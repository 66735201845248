$msv-swatch-item-background-color: $msv-white;
$msv-swatch-item-border-color:$msv-gray-800;
$msv-swatch-selected-item-border-color: #987f62;
$msv-swatch-selected-colored-item-inner-color: $msv-white;
$msv-swatch-item-size: 40px;
$msv-swatch-selected-colored-item-inner-size: 8px;

//todo in theme:  $msv-swatch-selected-item-border-color to 987f62 in theme

:root {
    --msv-swatch-item-background-color: #{$msv-swatch-item-background-color};
    --msv-swatch-item-border-color: #{$msv-swatch-item-border-color};
    --msv-swatch-selected-item-border-color: #{$msv-swatch-selected-item-border-color};
    --msv-swatch-selected-colored-item-inner-color: #{$msv-swatch-selected-colored-item-inner-color};
    --msv-swatch-item-size: #{$msv-swatch-item-size};
    --msv-swatch-selected-colored-item-inner-size: #{$msv-swatch-selected-colored-item-inner-size};
}

.ms-buybox__dropdown-quantity-label[for="ms-buybox__dropown-quantity-input-1"] div{
    display: inline-block;
}

.msc-swatch-container {
    display: flex;
    flex-wrap: wrap;

    &__item {
        min-width: var(--msv-swatch-item-size);
        height: var(--msv-swatch-item-size);
        margin: 5px 10px 5px 0;
        background-color: var(--msv-swatch-item-background-color);
        border: 1px solid var(--msv-swatch-item-border-color);
        box-sizing: border-box;
        cursor: pointer;

        @include font-content-m();
        color:  var(--msv-swatch-item-border-color);

        &__selected {
            border: 2px solid var(--msv-swatch-selected-item-border-color);
            color: var(--msv-swatch-selected-item-border-color);
            font-weight: bold;
            
        }

        &__disabled{
            opacity: 0.4;
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 0 L100 100 ' stroke='black' stroke-width='1.5'/></svg>");
            background-repeat: no-repeat;
        }

        &__colored, &__image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            &.msc-swatch-container__item__selected {
                position: relative;
                border: 3px solid $msv-swatch-selected-colored-item-inner-color;
                outline: 2px solid $msv-swatch-selected-item-border-color;
            }

            
            &__disabled {
                opacity: 0.4;
            }
        }

    }
}
