$msv-nile-newsletter-footer-link-color: $msv-blue-tint;
$msv-nile-newsletter-modal-header-padding-left: 10px;
$msv-nile-newsletter-title-padding-bottom: 12px;
$msv-nile-newsletter-subheading-padding-bottom: 20px;
$msv-nile-newsletter-modal-content-padding: 30px 25px;
$msv-nile-newsletter-modal-max-width: 480px;
$msv-nile-newsletter-modal-body-max-height: 355px;
$msv-nile-newsletter-modal-body-padding-left: 20px;
$msv-nile-newsletter-link-padding-top: 20px;
$msv-nile-newsletter-modal-body-display-padding-left: 30px;
$msv-nile-newsletter-displaystring-font-weight: 700;
$msv-nile-newsletter-displaysubstring-font-weight: 400;
$msv-nile-newsletter-current-site-button-width: 241px;
$msv-nile-newsletter-current-site-button-padding: 12px 48px;
$msv-nile-newsletter-current-site-button-border-radius: 100px;
$msv-nile-newsletter-current-site-button-border: 1px solid #{$msv-gray-30};
$msv-nile-newsletter-primary-btn-bg: #{$cb-btn-bg-color};

//style presets
:root {
    --msv-nile-newsletter-heading-font-color: var(--msv-font-primary-color);
    --msv-nile-newsletter-heading-font-size: var(--msv-body-font-size-m);
    --msv-nile-newsletter-link-font-size: var(--msv-body-font-size-m);
    --msv-nile-newsletter-button-font-size: var(--msv-body-font-size-s);
    --msv-nile-newsletter-link-font-color: #{$msv-gray-30};
    --msv-nile-newsletter-button-background: #{$msv-white};
}

.msc-modal__dialog {
    &.ms-nile-newsletter__dialog {
        max-width: $msv-nile-newsletter-modal-max-width;
    }

    .msc-modal__content {
        padding: $msv-nile-newsletter-modal-content-padding;
    }

    .ms-nile-newsletter__modal-header {
        border-bottom: none !important;
        padding-left: $msv-nile-newsletter-modal-header-padding-left;

        .msc-modal__title {
            .ms-nile-newsletter__heading {
                @include font-content(var(--msv-font-weight-normal),
                    var(--msv-nile-newsletter-heading-font-size), $msv-line-height-l);
                color: var(--msv-nile-newsletter-heading-font-color);
                padding-bottom: $msv-nile-newsletter-title-padding-bottom;
                font-size: 12px;
                text-transform: none;
                text-align: left;
            }

            .ms-nile-newsletter__subheading {
                @include font-content(var(--msv-font-weight-normal),
                    var(--msv-nile-newsletter-heading-font-size), $msv-line-height-l);
                color: var(--msv-nile-newsletter-heading-font-color);
                padding-bottom: $msv-nile-newsletter-subheading-padding-bottom;
                text-align: center;
            }
        }
    }

    .ms-nileNewsletter__modal-body {
        padding-bottom: 10px;
        padding-left: 10px;
        height: auto;
        max-height: $msv-nile-newsletter-modal-body-max-height;
        overflow: visible;
        justify-content: space-evenly;

        .pickNew {
            margin-top: 6px;
        }

        .pickerGroup {
            margin-top: 12px;
        }

        .ms-nile-newsletter__country__item__link {
            color: var(--msv-nile-newsletter-link-font-color);
            display: flex;
            padding-top: 0;

            .ms-nile-newsletter__country__item__img {
                padding-top: 5px;
            }

            .ms-nile-newsletter__country__item__display {
                padding-left: 0;

                &String {
                    @include font-content($msv-nile-newsletter-displaystring-font-weight,
                        var(--msv-nile-newsletter-link-font-size), $msv-line-height-l);
                    display: block;
                }

                &SubString {
                    @include font-content($msv-nile-newsletter-displaysubstring-font-weight,
                        $msv-font-size-s, $msv-text-line-height);
                }
            }
        }
    }

    .ms-nileNewsletter__modal-footer {
        flex-direction: column;
        align-items: center;
        padding-top: 1.6em;
        justify-content: center;

        .ms-nileNewsletter__link {
            @include font-content(var(--msv-font-weight-bold),
                var(--msv-nile-newsletter-link-font-size), $msv-line-height-l);
            color: $msv-nile-newsletter-footer-link-color;
            padding-top: 0;
        }

        .ms-nile-newsletter__current-site {
            @include button-default();
            @include primary-button($msv-nile-newsletter-primary-btn-bg);

            // @include font-content(var(--msv-font-weight-bold),
            //     var(--msv-nile-newsletter-button-font-size), $msv-line-height-m);
            // background: var(--msv-nile-newsletter-button-background);
            // border: $msv-nile-newsletter-current-site-button-border;
            // border-radius: 0;
            // color: var(--msv-nile-newsletter-link-font-color);
            // max-width: unset;
            // width: fit-content;
            // padding: $msv-nile-newsletter-current-site-button-padding;
            // transition: .3s ease;

            // &:hover {

            //     background: var(--msv-nile-newsletter-link-font-color);
            //     color: var(--msv-nile-newsletter-button-background);
            // }
        }
    }
}

.ms-nile-newsletter {
    &__section-heading {
        text-align: center;
        line-height: 28px;
    }

    &__button {
        align-self: center;
        padding-right: 10px;
    }

    &__yes-text {
        padding-right: 1em;
    }

    &__container {
        flex: auto;

        &__flex {
            display: flex;
            flex-direction: row;
        }
    }

    &__button-text {
        align-self: center;
        text-align: left;
    }

    &__birthday-input {
        width: 70%;
        height: 3rem;
        font-size: 18px;
        padding: 10px;
        text-align: center;
    }

    &__checkbox {
        width: 20px;
        height: 20px;
        padding-right: 12px;
        accent-color: $msv-nile-newsletter-primary-btn-bg;
    }

    &__smalltext {
        font-size: 12px;
        text-decoration: italic;
    } 
}

.ms-TextField-field.field-47 {
    margin-right: 0px;
}

.ms-TextField-field.field-58 {
    margin-right: 0px;
}

.ms-nile-newsletter__birthday-input div .ms-TextField .ms-TextField-wrapper .ms-TextField-fieldGroup {
    border: none !important;
}