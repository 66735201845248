$content-block-min-height: 150px;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 16px;
$hero-content-button-margin-top: 32px;
$hero-content-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108), 0 1.6px 3.6px rgba(0, 0, 0, 0.132);
$hero-heading-color: $black;
$hero-heading-font-size: 32px;
$hero-heading-line-height: 43px;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-top-margin: 32px;
$hero-links-secondary-padding: 12px 20px;

:root {
    // heading
    --msv-content-block-heading-font-size: #{$hero-heading-font-size};
    --msv-content-block-heading-font-color: var(--msv-font-primary-color);

    // fonts
    --msv-content-block-text-font: "brandon-grotesque";
    --msv-content-block-heading-font: "brandon-grotesque";
    
    // Text theme
    --msv-content-block-textheme-dark: var(--msv-font-primary-color);
    --msv-content-block-textheme-light: #{$msv-gray-100};
    --msv-content-block-text-font-size: var(--msv-body-font-size-m);

    // primary button
    --msv-content-block-primary-btn-bg: #{$cb-btn-bg-color};
    --msv-content-block-primary-btn-bg-hover: #{$cb-btn-bg-hover};
    --msv-content-block-primary-btn-border: #{$cb-btn-border-color};
    --msv-content-block-primary-btn-border-hover: #{$cb-btn-border-hover};
    --msv-content-block-primary-btn-border-width: #{$cb-btn-border-width};
    --msv-content-block-primary-btn-font-color: #{$cb-btn-bg-color};
    --msv-content-block-primary-btn-font-size: #{$cb-btn-text-size};
    --msv-content-block-primary-btn-font: #{$cb-btn-text-font};
    --msv-content-block-primary-btn-font-color-hover: #{$cb-btn-text-hover-color};
    --msv-content-block-primary-btn-font-hover-deco: #{$cb-btn-text-hover-deco};
    --msv-content-block-primary-btn-border-radius: #{$cb-btn-text-border-radius};

}

@mixin overlay-text-on-image {
    
    .ms-content-block__details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        left: 0;
        padding: 48px 60px 60px 60px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.ms-content-block {
    min-height: $content-block-min-height;
    .content-block-hide-details {
        .ms-content-block__details {
            display: none;
        }
    }

    &[data-m-layout="full-width"] {
        &.hero-style .ms-content-block__details{
            padding: 0;
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            right: unset;
            bottom: unset;
            transform: translate(-50%,-50%);
            height: auto!important;
            h1, h2{
                font-size: 6em;
                text-shadow: 0px 0px 12px var(--msv-video-title-drop-shadow-opacity);
                text-transform: uppercase;
                opacity: 1;
                line-height: 1em;
                text-align: center;
                margin-bottom: .5em;
                font-weight: 700;
            }
            .ms-content-block__cta{
                margin-top: 0;
                &__primary{
                    margin: 0;
                }
            }
            
        }
        position: relative;

        @include image(100%);

        .ms-content-block__image,
        .ms-content-block__image img {
            width: 100%;
        }

        .ms-content-block__image .ms-editable-field {
            /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
            width: 100% !important;
        }

        .ms-content-block__link {
            width: 100%;
        }

        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;

            @media (max-width: $msv-breakpoint-m) {
                position: static;
            }
        }

        &.textplacement__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;
            align-items: flex-end;
        }

        .ms-content-block__title {
            color: var(--msv-content-block-heading-font-color);
            font-weight: var(--msv-font-weight-normal);
            font-size: var(--msv-content-block-heading-font-size);
            font-family: var(--msv-content-block-heading-font);
            max-width: $hero-heading-max-length;
            line-height: $hero-heading-line-height;
            word-break: break-word;
        }

        .ms-content-block__text {
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-content-block-text-font-size), $msv-line-height-m);
            color: var(--msv-content-block-textheme-dark);
            max-width: $hero-body-max-length;
            word-break: break-word;
            font-family: var(--msv-content-block-text-font);
        }

        &.texttheme__light .ms-content-block__title, &.texttheme__light .ms-content-block__text {
            color: var(--msv-content-block-textheme-light);
            text-shadow: $hero-content-shadow;
        }

        &.texttheme__dark .ms-content-block__title, &.texttheme__dark .ms-content-block__text {
            color: var(--msv-content-block-textheme-dark);
        }

        /* body should have top margin only when there's something above it */
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        * + .ms-content-block__text {
            margin-top: $hero-content-text-margin-top;
            word-break: break-word;
        }

        .ms-content-block__cta {
            align-items: flex-end;
            justify-content: center;
        }

        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $hero-content-button-margin-top;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__details {
            @include msv-layout-page-side-padding;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            a.msc-cta__primary{
                @include button-default();
                @include primary-button(var(--msv-content-block-primary-btn-bg),
                var(--msv-content-block-primary-btn-font-color),var(--msv-content-block-primary-btn-border));
                margin: $hero-link-to-link-spacing / 2;
                display: inline-block;
                font-size: var(--msv-content-block-primary-btn-font-size);
                font-family: var(--msv-content-block-primary-btn-font);
                text-decoration: none;
                transition: .3s all ease-in-out;
                border: var(--msv-content-block-primary-btn-border-width) solid var(--msv-content-block-primary-border);
                border-radius: var(--msv-content-block-primary-btn-border-radius);
                &:hover{
                    background-color: var(--msv-content-block-primary-btn-bg-hover);
                    border-color: var(--msv-content-block-primary-border-hover);
                    color: var(--msv-content-block-primary-btn-font-color-hover);
                    text-decoration: var(--msv-content-block-primary-btn-font-hover-deco);
                }
                }

            .msc-cta__secondary {
                margin: $hero-link-to-link-spacing / 2;
                padding: $hero-links-secondary-padding;
            }
        }

        /**
    * When the image is not added in content block.
    */
        div.ms-content-block__image:empty {
            + div.ms-content-block__details {
                position: relative;
                height: unset;
            }
        }

        /**
    * Text over image by default only in modern browsers.
    * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
    */

        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }

            @include overlay-text-on-image;
        }

        /**
    * When the image is tall enough for IE 11 to display text on top of it.
    */

        @media screen and (min-width: $msv-breakpoint-l) {
            @include overlay-text-on-image;
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            .ms-content-block__details,
            &.textplacement__left .ms-content-block__details,
            &.textplacement__center .ms-content-block__details,
            &.textplacement__right .ms-content-block__details {
                width: max-content;
                height: 0;
                overflow: inherit;
            }

            &.textplacement__center .ms-content-block__details {
                margin: 0 auto;
            }

            &.textplacement__right .ms-content-block__details {
                margin-left: auto;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            .ms-content-block__cta {
                display: flex;
            }
        }
    }
}
