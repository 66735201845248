//spacing
$header-container-spacing-desktop: 40px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 25px;
$signin-info-padding: 12px 14px;
$header-height: 48px;
$header-height-collapsible-open: 100vh;
$header-mobile-height: 48px;
$header-icon-width: 48px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 25px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;
$header-store-selector-btn-padding: 12px 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-bg-color);
    --msv-header-border: #{$msv-primary};
    --msv-header-border-width: 1px; 
    --msv-header-font-color: #6e6e6e; // TODO: variable
    --msv-header-font-size: 24px;
}

header {
    background-color: var(--msv-header-bg);
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);
    border-bottom: var(--msv-header-border-width) solid var(--msv-header-border);
    margin-left: auto;
    margin-right: auto;
    //padding-left: $header-container-spacing-mobile;
    //padding-right: $header-container-spacing-mobile;
    padding-top: $heading-padding-top;
    @media only screen and (max-width: $msv-breakpoint-l) {
        height: auto;
    }
     height: 140px;
    &.ms-header-open {
        height: $header-height-collapsible-open;
    }

    &__container {
        padding: 0 80px;
        height: 100%;
        padding-bottom: $msv-default-container-padding-bottom;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        max-width: $msv-container-max-width-l;
        margin: auto;
        position: relative;
        .ms-header__logo {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            max-width: 56px;
            img{
                height: auto;
            }
            a {
                @include vfi();
                display: block;
                border: none;

                &:focus {
                    outline-offset: -2px;
                }
            }
        }

        .ms-header__collapsible-hamburger {
            .ms-header__mobile-hamburger-menu-links{
                position: absolute;
                transform: translate(-50%,100%);
                bottom: 0;
                width: 100%;
                left: 50%;
                background-color: var(--msv-header-bg);
            }
            .ms-header__divider {
                background: var(--msv-header-bg);
                border-bottom: 1px solid var(--msv-divider-color);
                padding: $divider-padding-m;
            }

            .ms-nav.mobile-vp {
                padding-top: $nav-padding-top-m;
                position: absolute;
                margin-top: 15%;
            }
        }
    }

    .ms-header__preferred-store-text {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        margin: 0 8px;
    }

    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button {
        background-color: transparent;
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;

        &:focus {
            outline-offset: -1px;
        }
    }

    .ms-header__profile-button {
        @include add-icon($msv-Contact, before);
        padding: 0;
        display: flex;
        align-items: center;
        &::before{
            font-size: 24px;
            margin-left: $header-myaccount-margin-left;
            text-align: center;
            vertical-align: middle;
        }
        .ms-profile-button-text{
            display: none;
        }
    }

    .ms-search__form-cancelSearch {
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), 24px, $msv-line-height-m);
        justify-content: center;
    }

    .ms-search__form-control,
    .ms-search__form-cancelSearch {
        &:focus {
            outline-offset: -1px;
        }
    }

    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info.account-desktop,
        .ms-header__account-info.account-mobile,
        .msc-cart-icon,
        .ms-header__divider,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .msc-btn {
        height: $header-height;
    }

    .ms-header__topbar {
        align-items: center;
        display: flex;
        //flex-grow: 1;
        flex-wrap: nowrap;
        height: min-content;
        min-height: 60px;
            .ms-header__account-info,
            .ms-site-picker__button{
                font-size: 20px;
                margin-left: 24px;
                justify-content: center;
            }
            .ms-header__wishlist-desktop,
            .msc-cart-icon{
                margin-left: 24px;
                justify-content: center;
                min-width: 60px;
                &::before{
                    width: auto;
                    font-size: 24px;
                }
            
            }
             .ms-search button.msc-btn{
                font-size: 24px;
                border-bottom: 1px solid transparent;
             }
        
    }

    .ms-search {
        display: none;
    }

    .ms-header__desktop-view {
        display: none;
    }

    .ms-header__account-info {
        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        //display: none;
        height: $header-height;
        justify-content: flex-end;
        width: auto;
        &__account-link-container {
            height: $account-link-height;

        }
        > a {
            @include add-icon($msv-Contact, before);

            > span{
                font-size: 0px;
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        a {
            &:before{
                font-size: 24px;
            }
            
            align-items: center;
            display: flex;
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
            padding: $signin-link-padding;
            height: $signin-link-height;

            &:hover {
                text-decoration: none;
            }
        }

        .ms-header__signin-button {
            background-color: var(--msv-header-bg);
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);
            
            &:focus {
                outline-offset: -1px;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;
            padding: 0 1em;
            
            a {
                color: #6e6e6e; // TODO: Variable
            }
            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: "";
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: var(--msv-border-color);
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-divider-color);
                    top: 1px;
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
            display: inline;
        }
    }

    .ms-header__wishlist-desktop {
        align-items: center;
        height: $header-height;
        width: $header-icon-width;
        padding: 0;
        @include add-icon($msv-Heart, before);

        &::before {
            font-size: var(--msv-header-font-size);
            text-align: center;
            vertical-align: middle;
            width: $header-icon-width;
        }

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__nav-icon {
        align-items: center;
        background: var(--msv-header-bg);
        display: flex;
        height: $header-height;
        justify-content: center;
        width: $header-icon-width;

        flex-grow: 1;
        justify-content: left;
        &:focus {
            outline-offset: -1px;
        }

        &::before {
            @include msv-icon();
            content: $msv-GlobalNavButton;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: middle;
            text-align: center;
            color:#6e6e6e;
            
        }

        &:hover {
            text-decoration: none;
           
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-icon {
        width: $header-icon-width;
        height: $header-height;
        display: flex;
        align-items: center;

        &::before {
            @include msv-icon(300);
            content: $msv-ShoppingCart;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: middle;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-mobile-left;
        padding-right: $header-container-spacing-mobile-right;

        .msc-wishlist-icon {
            @include add-icon($msv-Heart, before);
            &::before {
                display: inline;
                padding-right: 0;
                line-height: 48px;
            }
        }

        &__container {
            display: block;
            .ms-header__topbar {
                justify-content: space-between;

                .ms-header__logo {
                    top: auto;
                    transform: translateX(-50%);
                    width: 30px;
                    margin: 0;
                    .img {
                        max-width: 80%;
                    }
                }

                .ms-site-picker {
                    display: none;
                }
            }

            .ms-header__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                            color: var(--msv-header-font-color);
                            background: transparent;
                            text-align: left;
                            width: 100%;
                            // font-weight: bold;
                            &[aria-expanded='true'] .drawer__buttontext span{
                                color: #987f62; // TODO: Variable
                            }
                            // >span>span{
                            //     text-transform: uppercase;
                            // }
                            .ms-nav__drawer-open {
                                @include add-icon($msv-ChevronUp, after);
                            }

                            .ms-nav__drawer-close {
                                @include add-icon($msv-ChevronDown, after);
                            }

                            .drawer__glyph__end {
                                position: absolute;
                                right: 10px;
                            }
                        }

                        .msc-btn {
                            outline-offset: -1px;
                        }
                    }

                    .collapse, .collapsing {
                        .ms-nav__list__item__link{
                            text-transform: none;
                    }
                    }
                    .ms-nav__list__item__link,
                    .ms-nav__list__item__span {
                        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                        align-items: center;
                        display: flex;
                        padding: $header-list-item-link-padding;
                       
                    }
                    // > .ms-nav__list__item__link{
                    //     font-weight: bold;
                    // }
                }
            }
        }

        .ms-header__account-info.account-mobile {
            display: none;
            margin-left: 0;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .drawer__button {
                @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                @include add-icon($msv-Contact, before);
                
                &::before {
                    padding-right: $header-icon-padding-right;
                }
                background: transparent;
                text-align: left;
                width: 100%;

                .ms-header__drawer-open {
                    @include add-icon($msv-ChevronUp, after);
                }

                .ms-header__drawer-close {
                    @include add-icon($msv-ChevronDown, after);
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .drawer__glyph__end {
                    position: absolute;
                    right: $header-icon-padding-right;
                }
            }

            .ms-header__signin-button {
                

                @include add-icon($msv-Contact, before);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-btn {
                outline-offset: -1px;
            }
        }

        .ms-header__collapsible-hamburger {
            background: var(--msv-header-bg);
            left: 0;
            margin-top: 2px;
            position: absolute;
            width: 100%;
            z-index: 1012;
            //top:80%;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
                display: none;
            }

            .ms-search {
                justify-content: flex-start;
                display: flex;
                
                .ms-search__searchForm{
                    flex-direction: row-reverse;
                }

                .ms-search__label {
                    display: flex;
                    width: 100%;

                    .ms-search__icon {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }

                    .ms-search__icon-text {
                        display: unset;
                        padding-left: $header-icon-padding-left;
                    }
                }
            }
        }
    }
// header fix for 13" devices only
    @media screen and (min-width: $msv-breakpoint-l) and (max-width: 1520px) {
        .ms-header__container {
           padding: 0 70px !important;
        .ms-header__logo{
            max-width: 52px;
        }
        }

        .ms-header__topbar .ms-site-picker__button,
        .ms-header__topbar .ms-header__wishlist-desktop,
        .ms-header__topbar .msc-cart-icon,
        .ms-header .ms-header__topbar .ms-header__account-info {
            margin-left: 12px!important;
        }
        .ms-header__topbar .ms-site-picker__button,
        .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button, 
        .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__link, 
        .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__span
        {
            font-size: 16px!important;
            &::after{
                display: none;
            }
        }
        .ms-nav__list{
            justify-content: space-evenly;
        }

        .ms-nav>.ms-nav__list>.ms-nav__list__item{
            margin-right: 14px;
        }

        // padding-left: 18px!important;
        // padding-right: 18px!important;
    }
    
    @media (min-width: $msv-breakpoint-l) {
        .ms-header .ms-header__container .ms-header__logo {
            max-width: 52px;
        }
        //padding-left: $header-container-spacing-desktop;
        //padding-right: $header-container-spacing-desktop;

        .ms-header__desktop-view {
            display: block;
            width: calc(50% - 40px);
        }

        .ms-nav.desktop-vp {
            display: flex;
        }

        &__modal {
            display: none;
        }

        &__profile-button {
            padding: 12px 20px;
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);
            background: var(--msv-header-bg);
            transition: color .3s ease;
            &:hover{
                color: #987f62; // TODO: Variable
            }
            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: 20px;
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                vertical-align: middle;
            }
            
        }

        &__profile-button[aria-expanded="true"] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        &__profile-button[aria-expanded="false"] {
            &::after {
                content: $msv-ChevronDown;
            }
        }

        &__divider {
            display: none;
            background: $msv-divider-color;
            height: $divider-margin-height;
            margin-left: $divider-margin-left;
            margin-right: $divider-margin-right;
            width: $divider-margin-width;
        }

        .ms-header__account-info.account-desktop {
            button.ms-header__profile-button{
                display: flex;
                align-items: center;
            }
             display: block;                
            a{
                transition: color .3s ease;

                &:hover{
                    color: #987f62; // TODO: Variable
                }
            }
        }

        .ms-header__collapsible-hamburger {
            display: none;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }
        }

        .ms-search {
            display: flex;

            &__icon {
                align-items: center;
                color: var(--msv-header-font-color);
                font-weight: normal;
                transition: color .3s ease;
                &:focus {
                    outline-offset: -1px;
                }
                &:hover{
                    color: #987f62; // TODO: Variable
                }
            }
        }

        .ms-header__wishlist-desktop {
            transition: color .3s ease;
            &:hover{
                color: #987f62; // TODO: Variable
            }
            display: flex;
        }

        .ms-header__nav-icon {
            transition: color .3s ease;
            &:hover{
                color: #987f62; // TODO: Variable
            }
            display: none;
        }

        .ms-cart-icon {
            a{
                transition: color .3s ease;
                &:hover{
                    color: #987f62; // TODO: Variable
                }
            }
            padding-left: 0;
            width: unset;

            &::before {
                width: unset;
                margin-right: 4px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-header {
            &__preferred-store-btn {
                padding: $header-store-selector-btn-padding;
            }

            &__preferred-store-text {
                display: none;
            }
           
        }
        .ms-header__logo {
            max-width: 64px;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-header {
            &__container .ms-header__topbar{
                padding: 0 14px;
                button.ms-header__nav-icon{
                    padding: 0;
                }
                justify-content: center;
                .ms-header__wishlist-desktop{
                    margin-left: 5px !important;
                }
            }
        }
        .ms-header__logo {
            max-width: 64px;
        }

    .ms-header__account-info {
        margin-left: 10px !important;
    }
    .msc-cart-icon {
        margin-left: 0px !important;
    }

    .msc-wishlist-icon__count {
        display: none !important;
    }

    }

    @supports (-webkit-touch-callout: none) {
        .ms-header__preferred-store-btn,
        .ms-header__nav-icon {
            &:focus {
                border: none;
                outline: 0;
            }
        }
    }
}
