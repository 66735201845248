$tile-padding-bottom-mobile: 48px;
$tile-details-padding-top: 20px;
$tile-first-margin-left: 0;
$tile-last-margin-right: 0;
$tile-last-padding-bottom-mobile: 0;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 10px;

:root {
    --msv-content-block-link-color: var(--msv-font-secondary-color);
    --msv-content-block-button-color: var(--msv-accent-brand-color);
    --msv-content-block-link-font-size: var(--msv-body-font-size-m);
    // primary button
    --msv-content-block-primary-btn-bg: #{$cb-btn-bg-color};
    --msv-content-block-primary-btn-bg-hover: #{$cb-btn-bg-hover};
    --msv-content-block-primary-btn-border: #{$cb-btn-border-color};
    --msv-content-block-primary-btn-border-hover: #{$cb-btn-border-hover};
    --msv-content-block-primary-btn-border-width: #{$cb-btn-border-width};
    --msv-content-block-primary-btn-font-color: #{$cb-btn-bg-color};
    --msv-content-block-primary-btn-font-size: #{$cb-btn-text-size};
    --msv-content-block-primary-btn-font: #{$cb-btn-text-font};
    --msv-content-block-primary-btn-font-color-hover: #{$cb-btn-text-hover-color};
    --msv-content-block-primary-btn-font-hover-deco: #{$cb-btn-text-hover-deco};
    --msv-content-block-primary-btn-border-radius: #{$cb-btn-text-border-radius};

     // fonts
     --msv-content-block-text-font: "brandon-grotesque";
     --msv-content-block-heading-font: "brandon-grotesque";
     --msv-content-block-text-font-size: var(--msv-body-font-size-m);
     --msv-content-block-heading-font-size: #{$hero-heading-font-size};
}


.ms-content-block {
    &[data-m-layout="button-tile"],  &[data-m-layout="button-tile-half"] {
        margin: auto;
        width: fit-content;
        @media (max-width: $msv-breakpoint-m) {
            padding-top: 15px;
        }
        &.last{
            .ms-content-block__link .ms-content-block__image{
                margin-right: 0;
            }
            .ms-content-block__details {
                padding-right: 0;
            }
        }
        &.pos_tr .ms-content-block__details{
            right: 0;
            top: 0;
        }
    
        &.pos_tl .ms-content-block__details{
            left: 0;
            top: 0;
        }
        &.pos_br .ms-content-block__details{
            right: 0;
            
            bottom: 52px;
            @media (max-width: $msv-breakpoint-m) {
                bottom: 24px;
            }
        }
    
        &.pos_bl .ms-content-block__details{
            left: 0;
            
            bottom: 52px;
            @media (max-width: $msv-breakpoint-m) {
                bottom: 24px;
            }
        }

        &.pos_tc .ms-content-block__details{
            left: 50%;
            top: 0;
            transform: translate(-50%, 100%);
        }

        &.pos_bc .ms-content-block__details{
            left: 50%;
            bottom: 52px;
            @media (max-width: $msv-breakpoint-m) {
                bottom: 24px;
            }
            transform: translateX(-50%);
        }

        &.pos_c .ms-content-block__details{
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            
            .ms-content-block__cta{
                margin: 0;
                .msc-cta__primary{
                    text-align: center;
                }
            }
        }

        .ms-content-block__link {
            .ms-content-block__image{
                margin-right: 15px;
                
            }
            display: block;
        }

        .msc-cta__primary {
            @include button-link(var(--msv-content-block-link-color));
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-content-block-link-font-size), $msv-line-height-m);
        }

        .ms-content-block__details {
            width: 100%;
            padding: 0;
            padding-right: 15px;
            position: absolute;
            .ms-content-block__title{
                font-size: var(--msv-content-block-heading-font-size);
                font-family: var(--msv-content-block-heading-font);
                line-height: 1.25em;
                margin-bottom: 24px;
                color: #FFFFFF;
                text-shadow: 0px 0px 12px #00000045;
            }
            @media (max-width: $msv-breakpoint-m) {
                padding: $tile-details-padding-top;
            }
        }

        .ms-content-block__text {
            word-wrap: break-word;
            font-size: var(--msv-content-block-text-font-size);
            font-family: var(--msv-content-block-text-font);
        }

        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;

            .ms-content-block__cta {
                white-space: nowrap;
                text-align: center;
                a.msc-cta__primary{
                @include button-default();
                @include primary-button(var(--msv-content-block-primary-btn-bg),
                var(--msv-content-block-primary-btn-font-color),var(--msv-content-block-primary-btn-border));
                margin: $hero-link-to-link-spacing / 2;
                display: inline-block;
                font-size: var(--msv-content-block-primary-btn-font-size);
                font-family: var(--msv-content-block-primary-btn-font);
                text-decoration: none;
                transition: .3s all ease-in-out;
                border: var(--msv-content-block-primary-btn-border-width) solid var(--msv-content-block-primary-border);
                border-radius: var(--msv-content-block-primary-btn-border-radius);
                &:hover{
                    background-color: var(--msv-content-block-primary-btn-bg-hover);
                    border-color: var(--msv-content-block-primary-border-hover);
                    color: var(--msv-content-block-primary-btn-font-color-hover);
                    text-decoration: var(--msv-content-block-primary-btn-font-hover-deco);
                }
                }
                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-right: $tile-link-margin-left-right;

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right / 2;
                    margin-right: $tile-link-margin-left-right / 2;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            .ms-content-block__details{
                padding: 0;
            }
            .msc-cta__primary div {
                font-size: 1.5em;
                text-align: center;
                line-height: 1em;
            }

            .ms-content-block__link .ms-content-block__image {

            margin-right: 0;

            img {
                width: 100%;
            }
            .ms-editable-field {
                /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
                width: 100% !important;
            }
                
            }

        }
    }
}

.col-sm:first-child .ms-content-block[data-m-layout="tile"]:first-child,
.col-md:first-child .ms-content-block[data-m-layout="tile"]:first-child {
    margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child,
.col-md:last-child .ms-content-block[data-m-layout="tile"]:last-child {
    margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
    &.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child {
        padding-bottom: $tile-last-padding-bottom-mobile;
    }
}
