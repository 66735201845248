$footer-ms-footer-padding-xl: 48px 0;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-max-width: 224px;
$footer-category-padding-bottom: 48px;
$footer-item-padding-desktop: 0;
$footer-item-padding-right-mobile: 28px;
$footer-heading-title-line-height: 24px;
$footer-heading-title-margin-bottom: .5em;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 0;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;
$footer-back-to-top-border-radius: 2px;
$footer-categoty-back-to-top-max-width: 0;
$footer-placeholder-image-width: 24px;

:root {
    --msv-footer-border-top-color: #987f62;
    --msv-footer-background-color: #FAF8F7;
    --msv-footer-border-top-width: 1px;

    // Headings
    --msv-footer-heading-font-color: #987f62; 
    --msv-footer-heading-font-size: 18px; 
    --msv-footer-heading-font-family: inherit;
    --msv-footer-heading-font-weight: bold;

    //Links
    --msv-footer-link-font-color: #6e6e6e; 
    --msv-footer-link-font-size: 18px; 
    --msv-footer-link-font-family: inherit; 
    --msv-footer-link-hover-color: #3d3d3d; 
    //Text 
    --msv-footer-text-font-color: #6e6e6e;
    --msv-footer-text-font-family: inherit;
    
    //BTT
    --msv-btt-bg-color: #fff;
    --msv-btt-bg-hover-color:#987f62;
    --msv-btt-fg-color: #987f62;
    --msv-btt-fg-hover-color: #fff;
    --msv-btt-border-width: 1px;
    --msv-btt-border-color: #987f62;
    --msv-btt-border-hover-color: #987f62;
    --msv-btt-border-hover-width: 1px;
}


footer {
    background-color: var(--msv-footer-background-color);
    border-top-width: var(--msv-footer-border-top-width);
    border-top-style: solid;
    border-top-color: var(--msv-footer-border-top-color);
    line-height: 24px;
    .alert-dismissible{
        span.msc-alert{
            display: inline;
        }
        button.close{
            border: none;
            font-weight: bold;
            padding-right: 6px;
            font-size: 1.5em;
            vertical-align: bottom;
            background-color: transparent;
            color: var(--msv-accent-brand-color);
            display: inline;
        }
    }
    .ms-footer {
        &.aad_footer{
            & > * {
                justify-content: flex-end;
                .ms-footer__link{
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
        &__list {
            flex: initial;
            width: auto;
        }

        .ms-back-to-top {
            @include vfi();
            background: var(--msv-bg-color);
            border-color: var(--msv-btt-border-color);
            border-width: var(--msv-btt-border-width);
            border-style: solid;
            border-radius: $footer-back-to-top-border-radius;
            bottom: $footer-back-to-top-bottom;
            cursor: pointer;
            display: none;
            height: $footer-back-to-top-height;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: fixed;
            right: $footer-back-to-top-right;
            text-align: center;
            vertical-align: middle;
            width: $footer-back-to-top-width;
            transition: all .3s ease-in-out;

            &:hover {
                background: var(--msv-btt-bg-hover-color);
                border-color: var(--msv-btt-border-hover-color);
                .fa-arrow-up{
                    color: var(--msv-btt-fg-hover-color);
                }
            }

            .fa-arrow-up {
                color: var(--msv-btt-fg-color);
                font-size: var(--msv-body-font-size-xl);
                left: 50%;
                line-height: $footer-back-to-top-line-height;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                
                &::before {
                    @include msv-icon();
                    content: $msv-ChevronUp;
                }
            }
        }

        p{
            margin-bottom: 1em;
            color: var(--msv-footer-text-font-color);
            font-size: var(--msv-footer-link-font-size);
            font-family: var(--msv-footer-text-font-family);
        }

        .ms-back-to-top[aria-hidden="false"] {
            display: block;
            opacity: 1;
            transform: translateX(0%);
        }

        &__item {
            padding: $footer-item-padding-desktop;
        }

        .change-country-link a div{
            &:hover, &:active{
                color: var(--msv-accent-brand-alt-color);
            }
            &::after {
                @include msv-icon();
                padding-left: .5em;
                content: $msv-ChevronRight;
            }
        }

        &__heading__title,
        .change-country-link a div,
        .ms-subscribe__details__heading {
            color: var(--msv-footer-heading-font-color);
            font-size: var(--msv-footer-heading-font-size);
            font-weight: var(--msv-footer-heading-font-weight);
            font-family: var(--msv-footer-heading-font-family);
            line-height: $msv-line-height-l;
            margin-bottom: $footer-heading-title-margin-bottom;
            letter-spacing: -.25px;
            text-transform: uppercase;
            white-space: nowrap;
        }

        &__link {
            @include vfi();
            display: inline-flex;
            color: var(--msv-footer-link-font-color);   
            font-size: var(--msv-footer-link-font-size);
            @include image($footer-placeholder-image-width);
            &:hover{
                text-decoration: none;
                div{
                    transition: .3s all ease;
                    color: var(--msv-footer-link-hover-color);
                    
                }
            }
            img {
                @include vfi();
            }

            div, &__text {
                color: var(--msv-footer-link-font-color);
                font-size: var(--msv-footer-link-font-size);
                font-weight: var(--msv-font-weight-normal);
                line-height: 36px;
            }
        }

        & > * {
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            padding: $footer-ms-footer-padding-xl;
            > :nth-child(3){
                
                max-width: calc(#{$footer-category-max-width} * 1.5);
            }
            > * {
                max-width: $footer-category-max-width;
                padding-bottom: $footer-category-padding-bottom;
                &:nth-child(4){
                    padding-bottom: 78px;
                }
                ul.ms-footer__list{
                    @media (max-width: $msv-breakpoint-m){
                        .change-country-link{
                            bottom: 0;
                            position: absolute;
                        }
                        .ms-subscribe__details__form__submit.msc-btn{
                            flex-grow: 0;
                            width: 50px;
                        }
                    }
                 ul.ms-footer__list
                 { // socials column
                        display: flex;
                        flex-wrap: wrap;
                        width: auto;
                        padding-top: 1em;
                    .ms-footer__list-item picture{
                        opacity: .6;
                        transition: .3s opacity ease-in-out;
                        &:hover{
                            opacity: .8;
                        }
                    }
                    .ms-footer__list-item:first-child {
                        // position: absolute;
                        width: 100%;
                        flex: 0 1 33%;
                        margin-bottom: 1em;
                    }

                    .ms-footer__list-item:nth-child(2) {
                        position: relative;
                        // top: $footer-list-item-icon-top;
                        width: 100%;
                        flex: 1 0 33%;
                        // transform: translateY(300%);

                    }
                    @media (max-width: $msv-breakpoint-m){
                        .ms-footer__list-item:first-child,
                        .ms-footer__list-item:nth-child(2),
                        .ms-footer__list-item:nth-child(3) {
                            flex: none;
                            width: 50px;
                           >div{ 
                               padding-right: 0;
                           }
                        }
                       
                    }

                    @for $i from 3 to ($footer-list-item-icon-number + 2) {
                        .ms-footer__list-item:nth-child(#{$i}) {
                            // left: $footer-list-item-icon-left * ($i - 2);
                            position: relative;
                            width: 100%;
                            flex: 1 0 33%;
                                                }
                        @media (max-width: $msv-breakpoint-m){
                            .ms-footer__list-item:nth-child(#{$i}){
                                // display: none;
                                z-index: 5;
                                width: 50px;

                                > div{
                                    padding-right: 0;
                                }
                            }
                        }

                    }
                }
            }

                &:last-child {
                    max-width: $footer-categoty-back-to-top-max-width;
                }
            }
        }
        .ms-subscribe__details__form{
            display: flex;
            flex-wrap: nowrap;
            height: 38px;
            &__email{
                flex-grow: 1;
                border: 1px solid #987f62;// TODO: variable
                border-radius: 0;// TODO: variable
                padding: 0 12px;
                font-size: 18px;
                &::placeholder{
                    opacity: .7;
                }
            }
            &__submit{
                padding: 0 6px;
                font-size: 0;
                background-color: #987f62;
                color: white;
                font-weight: bold;
                border: 0;
                border-radius: 0;
                width: 50px;
                height: auto;
                transition: all .3s ease;
                &:before{
                    content: $msv-arrow-right;
                    font-size: 14px;
                    font-family: $msv-icon-font-family;
                    top: 3px;
                    position: relative;
                    font-weight: 300;
                }
                &:hover, &:active{
                    background-color: var(--msv-accent-brand-alt-color);
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-footer {
            margin-left: $footer-ms-footer-margin-left-mobile;
            padding: 0 15px;
            &__item {
                padding-right: $footer-item-padding-right-mobile;
            }

            > * {
                padding-top: $footer-row-padding-top-mobile;

                > * {
                    max-width: 100%;

                    &:nth-child(4) {

                        @for $i from 3 to ($footer-list-item-icon-number + 2) {
                            .ms-footer__list-item:nth-child(#{$i}) {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
