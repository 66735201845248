$msv-flyout-msc-cart-line-product-image-size: 120px;
$msv-flyout-msc-cart-line-product-empty-image-size: 80px;
$msv-flyout-padding: 20px;
$msv-flyout-cart-icon-subtotal-padding: 20px;
$msv-flyout-cart-icon-subtotal-margin: 0 -20px;
$msv-flyout-cart-icon-btn-checkout-margin-right: 10px;
$mini-cart-width: 400px;
$subtotal-border: 1px solid $msv-gray-300;
$msv-flyout-empty-img-border: 1px solid $msv-flyout-empty-img-border;

//style presets
:root {
    --msv-flyout-heading-font-color: var(--msv-font-primary-color);
    --msv-flyout-heading-font-size: var(--msv-body-font-size-l);
    --msv-flyout-text-font-color: var(--msv-font-primary-color);
    --msv-flyout-text-font-size: var(--msv-body-font-size-m);

    // primary button
    --msv-flyout-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-flyout-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-flyout-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-flyout-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-flyout-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-flyout-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-cart-icon__flyout-container {
    background: var(--msv-flyout-bg-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: $mini-cart-width;
    z-index: 1060;
    max-width: 100%;

    &.show {
        opacity: 1;
        padding: $msv-flyout-padding;
    }

    .ms-cart-icon__btn-checkout,
    .ms-cart-icon__btn-gotocart,
    .ms-cart-icon__btn-guestcheckout {
        width: 100%;
    }

    .msc-flyout__close-button {
        @include add-icon($msv-x-shape);
        @include vfi();
        background-color: transparent;
        border: 0;
        color: var(--msv-font-primary-color);
        font-size: 20px;
        position: absolute;
        right: 20px;
        cursor: pointer;
        &:hover {
            color: var(--msv-accent-brand-color);
        }
    }

    .msc-flyout-inner {
        max-width: 100%;
        .ms-cart-icon__subtotal {
            @include font-content(var(-msv-font-weight-bold),
            var(--msv-body-font-size-l), $msv-line-height-l);
            color: var(--msv-flyout-text-font-color);
            border-top: $subtotal-border;
            margin: $msv-flyout-cart-icon-subtotal-margin;
            padding: $msv-flyout-cart-icon-subtotal-padding;
            text-align: right;
        }

        .ms-cart-icon__btn-gotocart {
            @include secondary-button(var(--msv-flyout-secondary-btn-bg),
            var(--msv-flyout-secondary-btn-font-color), var(--msv-flyout-secondary-btn-border));
            font-weight: 500;
            text-decoration: none;;
            text-transform: uppercase;
        }

        .ms-cart-icon__btn-checkout, .ms-cart-icon__btn-guestcheckout {
            @include primary-button(var(--msv-flyout-primary-btn-bg),
            var(--msv-flyout-primary-btn-font-color), var(--msv-flyout-primary-btn-border));
            margin-bottom: 20px;
            font-weight: 500;
            text-transform: uppercase;
            &:hover{
                color: #fff;
            }
        }
    }
    .msc-cart-line {
        
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 0;
        &-product-details {
            width: auto;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            .msc-cart-line__product-image {
                width: 70px;
                margin-right: 10px;
                img {
                    height: auto;
                }
            }
        }
        
        @media screen and (max-width: $msv-breakpoint-m) {
            & > div {
                flex: auto;
                margin-bottom: 0;
            }
        }

        .msc-empty_image {
            border: $msv-flyout-empty-img-border;
        }
    
        // &__product-savings-label,
        // &__product-savings-text,
        // &-item-product-discount {
        //     display: none;
        // }
    
        &__remove-item {
            @media screen and (max-width: $msv-breakpoint-m) {
                width: auto;
            }
            margin: 0;
            float: right;
            transform: translateY(-100%);
            text-decoration: none;
            &:hover {
                color: var(--msv-accent-brand-color);
            }
        }
    
        &__bopis-method {
            margin-left: 108px;
        }
    
        &__remove-item,
        &__bopis-method {
            height: auto;
            padding-top: 6px;
        }
    
        &__content {
            display: block;
        }
    
        &__product-savings {
            text-align: right;
            margin: 0;
        }
    
        &__product-savings,
        &__quantity,
        &__product-variants {
            margin-top: 0;
            margin-left: 0;
            font-size: 16px; 
            line-height: 1.2em;
        }
    
        &__product-variants {
            & > .msc-cart-line__product-variant-item {
                display: inline;
    
                &::after {
                    content: ", ";
                }
    
                &:last-child::after {
                    content: "";
                }
            }
        }
    
        &__product-variant-size,
        &__product-variant-style,
        &__product-configuration,
        &__product-variant-color {
            font-size: 0;
    
            .name {
                @include font-content(var(--msv-font-weight-normal), var(--msv-flyout-text-font-size), $msv-line-height-m);
                color: var(--msv-flyout-text-font-color);
                padding-left: 0;
            }
        }
    
        &__product-title {
            color: $nile-header-color;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.2em;
            font-weight: var(--msv-font-weight-bold);
            &:hover {
                color: var(--msv-accent-brand-color);
            }
        }
    

    
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        &__product-variant-item,
        &__quantity,
        &__bopis-method {
            @include font-content(var(--msv-font-weight-light), var(--msv-flyout-text-font-size), $msv-line-height-m);
            color: var(--msv-flyout-text-font-color);
            .quantity-label{
                display: none;
            }
            .quantity-value{
                word-break: keep-all;
                white-space: nowrap;
                &::before{
                    content: "× ";
                }
            }
        }
    }
    .ms-cart-icon__cart-lines {
        max-height: 400px;
        overflow: auto;
        margin-right: -15px;
        padding-right: 15px;

        .msc-cart-lines-item {
            min-height: unset;
            padding-top: 1em;
            padding-bottom: 1em;
            margin-top: 0;
            margin-bottom: 0;
            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-button {
            width: 8px;
        }

        &::-webkit-scrollbar-button {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #EEEEEE;
            border: thin solid #DFDFDF;
            box-shadow: 0 0 3px #DFDFDF inset;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #999999;
            border: thin solid #323130;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #7D7D7D;
        }
    }
    .ms-cart-icon__flyout-title {
        @include font-content(var(--msv-font-weight-bold), var(--msv-flyout-heading-font-size), $msv-line-height-l);
        color: var(--msv-flyout-heading-font-color);
        text-transform: uppercase;
        font-weight: bold;
    }
    .ms-cart-icon__flyout-title {
        margin-bottom: 10px;
    }
}

.msc-flyout-inner .ms-cart-icon__cart-lines .msc-cart-lines-item .msc-cart-line .msc-cart-line-product-details .msc-cart-line__product .ms-product-details .article-no-div {
    display: none;
}

// .msc-flyout-inner .ms-cart-icon__cart-lines .msc-cart-lines-item .msc-cart-line .msc-cart-line-product-details .msc-cart-line__product .ms-product-details {
//     display: none;
// }

.horizonal {
    display:flex;
    flex: auto !important;
    flex-direction: column !important;
}
// .msc-product__details .article-number {
//     display: none;
// }
