$tile-padding-bottom-mobile: 48px;
$tile-details-padding-top: 20px;
$tile-first-margin-left: 0;
$tile-last-margin-right: 0;
$tile-last-padding-bottom-mobile: 0;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 10px;

:root {
    // link
    --msv-content-block-link-color: var(--msv-font-primary-color);
    --msv-content-block-link-font-size: var(--msv-body-font-size-m);

     // fonts
     --msv-content-block-text-font: "brandon-grotesque";
     --msv-content-block-heading-font: "brandon-grotesque";
     --msv-content-block-text-font-size: var(--msv-body-font-size-m);
     --msv-content-block-heading-font-size: #{$hero-heading-font-size};
        // primary button
    --msv-content-block-primary-btn-bg: #{$cb-btn-bg-color};
    --msv-content-block-primary-btn-bg-hover: #{$cb-btn-bg-hover};
    --msv-content-block-primary-btn-border: #{$cb-btn-border-color};
    --msv-content-block-primary-btn-border-hover: #{$cb-btn-border-hover};
    --msv-content-block-primary-btn-border-width: #{$cb-btn-border-width};
    --msv-content-block-primary-btn-font-color: #{$cb-btn-bg-color};
    --msv-content-block-primary-btn-font-size: #{$cb-btn-text-size};
    --msv-content-block-primary-btn-font: #{$cb-btn-text-font};
    --msv-content-block-primary-btn-font-color-hover: #{$cb-btn-text-hover-color};
    --msv-content-block-primary-btn-font-hover-deco: #{$cb-btn-text-hover-deco};
    --msv-content-block-primary-btn-border-radius: #{$cb-btn-text-border-radius};

}

.ms-content-block {
    &[data-m-layout="side-to-side"] {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: space-around;
        flex-direction: column;
        position: relative;
        margin: auto;
        margin-top: 20px; 
        width: calc(100% - 10px);
        height: calc(100% - 20px);
        .ms-content-block__link {
            display: block;
        }


        &.read-more-tile .ms-content-block__details {
            color: white;
            width: 100%;
            display: block;
            position: relative;
            padding: 20px;

            a.msc-cta__primary{
                @include button-default();
                @include primary-button(var(--msv-content-block-primary-btn-bg),
                var(--msv-content-block-primary-btn-font-color),var(--msv-content-block-primary-btn-border));
                margin: $hero-link-to-link-spacing / 2;
                display: inline-block;
                font-size: var(--msv-content-block-primary-btn-font-size);
                font-family: var(--msv-content-block-primary-btn-font);
                text-decoration: none;
                transition: .3s all ease-in-out;
                border: var(--msv-content-block-primary-btn-border-width) solid var(--msv-content-block-primary-border);
                border-radius: var(--msv-content-block-primary-btn-border-radius);
                &:hover{
                    background-color: var(--msv-content-block-primary-btn-bg-hover);
                    border-color: var(--msv-content-block-primary-border-hover);
                    color: var(--msv-content-block-primary-btn-font-color-hover);
                    text-decoration: var(--msv-content-block-primary-btn-font-hover-deco);
                }
                }
        }

        .ms-content-block__text {
            word-wrap: break-word;
            h3{
                font-size: 24px;
                line-height: 1.25em;
            }
            p{
                font-size: 18px;
                line-height: 1.6em;
            }
        }
        &.read-more-tile .ms-content-block__image {
            
            mix-blend-mode: multiply; 
            margin-left: auto;
            margin-right: auto;
            width: 80px;
            position: absolute;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
        }
        &.image-tile img {
            margin-right:0px;
            width: 100%;
          
        }
        

        

        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;

            .ms-content-block__cta {
                padding-top: $tile-link-padding-top;

                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right;


                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-right: $tile-link-margin-left-right;

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right / 2;
                    margin-right: $tile-link-margin-left-right / 2;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: $tile-padding-bottom-mobile;
            width: 100%;

            & .ms-content-block__image img {
                width: 100%;
            }

            & .ms-content-block__image .ms-editable-field {
                /* stylelint-disable-next-line declaration-no-important -- Used to override default image inline style for the placeholder in editorial mode. */
                width: 100% !important;
            }
        }
    }
}

.row > div{
    &:nth-child(even) > .ms-content-block[data-m-layout="side-to-side"]{
        margin-right: 0;
        margin-left: auto;
    }
    &:nth-child(odd) > .ms-content-block[data-m-layout="side-to-side"]{
        margin-left: 0;
        margin-right: auto;
    }
}

// .col-sm:first-child .ms-content-block[data-m-layout="tile"]:first-child,
// .col-md:first-child .ms-content-block[data-m-layout="tile"]:first-child {
//     margin-left: $tile-first-margin-left;
// }

// .col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child,
// .col-md:last-child .ms-content-block[data-m-layout="tile"]:last-child {
//     margin-right: $tile-last-margin-right;
// }

// @media (max-width: $msv-breakpoint-m) {
//     &.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child {
//         padding-bottom: $tile-last-padding-bottom-mobile;
//     }
// }
