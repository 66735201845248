div.contact-form 

{
    h1 {
        padding: 1rem;
    } 
    form{

        button {
            @include primary-button();
            @include font-content-l();
            text-transform: uppercase;
            cursor: pointer;
            font-weight: bold;
        }

        > div {
            margin-bottom: 36px;
            display: inline-block;
            padding: 0 1rem;
            .error{
                color: #{$msv-error-color};
                line-height: 2em;
                position: absolute;
            }
        }

        input, select {
            @include form-control();
        }

        textarea {
            @include form-control();
            height: 120px;
        }

        label {
            @include form-input-label();
        }


        }
}