$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 50px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-items-border-radius: 2px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;
$nile-checkout-font-weight: 500;

//style presets
:root {
    --msv-order-summary-bg: var(--msv-bg-color);
    --msv-order-summary-font-color: var(--msv-font-primary-color);
    --msv-order-summary-font-size: 16px;
    --msv-order-summary-border: var(--msv-font-primary-color);

    // heading
    --msv-order-summary-heading-font-color: #{$nile-header-color};
    --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.cartpage .msc-order-summary {
    &-wrapper{
        .msc-order-summary__line-shipping,.msc-order-summary__line-tax-amount{
            display: none;
        }
        .msc-order-summary__line-sub-total{
            padding-bottom: 25px;
        }
    }
}

.msc-order-summary {
        
    &-wrapper {
        
        align-items: center;
        background-color: var(--msv-bg-accent-color);
        margin-left: 1em;
        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;
        padding: $msv-order-summary-padding $msv-order-summary-padding 20px $msv-order-summary-padding;
        height: fit-content;
        @media screen and (min-width: $msv-breakpoint-s) {
            // min-width: max-content;
            min-width: 33%;
            width: 33%;
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 25px;
        }
        
        .msc-promo-code__input-box {
            border: none;
            margin-bottom: 20px;
            height: 35px;
        }
        .msc-promo-code__apply-btn{
            height: 35px;
        }
        .msc-order-summary__line-shipping{
            padding-bottom: 0px;
        }
        .msc-order-summary__heading{
            padding-bottom: 15px;
            color: $nile-header-color;
            margin-bottom: 16px; 
            font-size: 28px;
        }
        .msc-cart__btn-guestcheckout,.msc-cart__btn-checkout{
            margin-top:0px;
            text-transform: uppercase;
            padding: 10px 20px;
            font-weight: $nile-checkout-font-weight;
        }
        .msc-cart__btn-backtoshopping {
            color: #796752;
            float: right;
            margin-left: auto;
            &::before {
                font-family: $msv-icon-font-family;
                content: "\f053";
                padding-right: 6px;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-heavy),
        28px, $msv-line-height-xl);
        color: var(--msv-order-summary-heading-font-color);
        font-style: $msv-order-summary-font;
        padding-bottom: 30px;
        width: 100%;
        text-transform:uppercase;
        hyphens: auto;
    }

    &__items {
        color: var(--msv-order-summary-font-color);
        border-radius: $msv-order-summary-items-border-radius;
        text-transform:uppercase;
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-summary-font-size), $msv-line-height-l);
        width: 100%;
        text-transform:uppercase;
        margin-bottom: 10px;
        
        .msc-order-summary__value, .msc-price__actual { 
            color: var(--msv-cart-heading-font-color); 
            margin-left: 14px;
        }

        .msc-promo-code {
            &-heading{

                font-size: 16px;
            }
            &__group {
                display: flex;
                padding-top: 10px;
            }

            &__input-box {
                flex: auto;
                margin-right: 10px;
                padding-left: 8px;
                font-size: 14px;
            }
            &__apply-btn {
                min-width: 35px;
            }
        }
    }

    &__line {
        &-net-price,
        &-tax-amount,
        &-shipping,
        &-loyalty,
        &-gift-card,
        &-other-charges,
        &-total-discounts {
            padding: 24px 0 0;
            margin: 8px 0;
        }

        &-total {
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-order-summary-font-size), $msv-line-height-l);
            padding: 15px 0 24px;
            border-top: 2px solid #a8a7a7;
            text-transform: uppercase;
        }
    }

    &__value {
        font-weight: 600;
        float: right;
        text-align: right;
        font-weight: var(--msv-font-weight-bold);
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .msc-order-summary-wrapper {
        margin: 0;
    }
}
