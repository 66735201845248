// Form elements
$msv-form-selections-accent-color: #333;
$msv-form-input-background-color: #FFF;
$msv-form-input-text-color: #333;
$msv-form-input-border-color: #E7E7E7;
$msv-form-input-border-width: 1px;
$msv-form-input-border-radius: 2px;

:root {
    --msv-form-selections-accent-color: #{$msv-form-selections-accent-color};
    --msv-form-input-background-color: #{$msv-form-input-background-color};
    --msv-form-input-text-color: #{$msv-form-input-text-color};
    --msv-form-input-border-color: #{$msv-form-input-border-color};
    --msv-form-input-border-width: #{$msv-form-input-border-width};
    --msv-form-input-border-radius: #{$msv-form-input-border-radius};
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z' fill='%23C4C4C4'/%3E%3C/svg%3E");    
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 12px;

}

@mixin error-message() {
    @include font-content-m(400);
    display: block;
    color: var(--msv-error-color);
    font-style: italic;
}

@mixin form-control() {
    display: block;
    width: 100%;
    color: $msv-gray-tint;
    background-color: var(--msv-form-input-background-color);
    border: var(--msv-form-input-border-width) solid var(--msv-form-input-border-color);
    border-radius: var(--msv-form-input-border-radius);
    font-family: "brandon-grotesque", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-transform: none;
    height: 48px;
    padding: 4px 8px;
    background-clip: border-box;
    position: relative;
    &:focus {
        border: 1px solid $msv-gray-300;
        outline: none;
    }
}

@mixin form-input-label() {
    @include font-content-l();
    display: block;
    margin-bottom: 4px;
}

@mixin form-input-checkbox() {
    height: 18px;
    width: 18px;
    accent-color: var(--msv-form-selections-accent-color);
}

@mixin form-input-radio {
    // @include vfi();
    vertical-align: middle;
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 0;
    accent-color: var(--msv-form-selections-accent-color);
}

@mixin form-input-radio-label {
    @include font-content-m(var(--msv-font-weight-light));
    vertical-align: middle;
    margin-left: 20px;
}

@mixin form-input-el($bg-color: var(--msv-form-input-background-color), $color: var(--msv-form-input-text-color), $border: var(--msv-form-input-border-color)) {
    // @include vfi();
    @include font-content-l();
    height: 48px;
    padding: 4px 8px;
    background-color: $bg-color;
    color: $color;
    border-color: $border;
}

@mixin form-input-el-error() {
    border: 1px solid var(--msv-error-color);
    border-left: 3px solid var(--msv-error-color);
}

@mixin form-input-error-message() {
    @include error-message();
    @include add-icon($msv-IncidentTriangle);
    margin-bottom: 4px;

    &::before {
        margin-right: 8px;
    }
}

@mixin form-input-alert-message() {
    @include font-content-s-height-l();
    color: $msv-text-color;
    margin-bottom: 4px;
}

@mixin alert {
    background-color: $pink;
    border: 1px solid $red;
    max-width: 512px;
    padding: 8px;
}

@mixin alert-title {
    @include error-message();
    margin-bottom: 10px;
    margin-top: 2px;

    &::before {
        @include font-awesome-icon($alert-icon);
        margin-right: 8px;
    }
}
