$msv-checkout-guest-profile-input-margin-top: 4px;
$msv-checkout-guest-profile-input-height: 32px;
$msv-checkout-guest-profile-input-label-width: 100%;
$msv-checkout-guest-profile-input-text-max-width: 610px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;

.ms-checkout-guest-profile {
    &__add-contact-info {
        width: 100%;
    }

    &__input {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding-bottom: 28px;
        &.is-invalid input{
            border: 1px solid var(--msv-error-color);
        }
    }

    &__selected-email {
        @include font-content(var(--msv-font-weight-normal), 18px, 18px);
        padding-bottom: 1em;
        display: block;
    }

    &__input-text {
        @include vfi();
        @include form-input-el($msv-white,var(--msv-font-primary-color) ,#e7e7e7);
        display: block;
        flex-grow: 1;
        max-width: $msv-checkout-guest-profile-input-text-max-width;
        border-width: 1px;
        border-style: solid;
    }

    &__input-label {
        @include font-content-l();
        width: $msv-checkout-guest-profile-input-label-width;
        margin-bottom: $msv-checkout-guest-profile-input-margin-top;
    }

    &__input-error {
        @include form-input-error-message();
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.ms-checkout__line-item .msc-cart-line .msc-cart-line-product-details .msc-cart-line__product .ms-product-details .horizontal .article-no-div {
    display: none;
}