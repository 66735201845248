$msc-account-welcome-title-margin-bottom: 40px;
$msc-account-welcome-title-margin-top:32px;
$msc-account-welcome-title-border-bottom-color: var(--msv-divider-color);
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-welcome-title-links-padding-top: 12px;
$msc-account-tile-heading-icon-padding: 5px;
$msc-generic-tile-heading-margin-bottom: 0.5rem;
$msc-link-padding-right: 12px;

// style presets
:root {
    --msv-account-landing-title-font-color: #{$nile-header-color};

    // title
    --msv-account-landing-title-font-size: 32px;
    --msv-account-landing-title-desktop-font-size: 32px;

    // tiles
    --msv-account-landing-tile-heading-size: 20px;
    --msv-account-landing-tile-heading-color: #333;
    --msv-account-landing-tile-text-size: var(--msv-body-font-size-m);
    --msv-account-landing-tile-text-color: var(--msv-font-primary-color);
    --msv-account-landing-tile-background-color: var(--msv-bg-accent-color);

    // link
    --msv-account-landing-link-color: #{$msv-link-color};
    --msv-account-landing-link-size: var(--msv-body-font-size-m);
}

// small/mobile screens
@media (max-width: $msv-breakpoint-l) {
    #main{
        .account-landing-background-image-container {
            display: none!important;
        }

        .container.account-tiles-container{
            width: 100%;
            .ms-account-generic-tile,
            .ms-account-wishlist-tile,
            .ms-account-address-tile,
            .ms-account-loyalty-tile{
                background-color: transparent;
                margin: 0;
                padding: 1em 1em 4em;
            }
        }
    }
}

.container.account-tiles-container {
    display: inline-block;
    .ms-account-generic-tile,
    .ms-account-wishlist-tile,
    .ms-account-address-tile,
    .ms-account-loyalty-tile{
        background-color: var(--msv-account-landing-tile-background-color);
        padding: 2em;
        margin: 0 0 2em 2em;
        height: calc(100% - 2em);
    }
}

.account-image-container .ms-container-background-image.lazyloaded {
    min-height: 468px!important;

    @media screen and (max-width: $msv-breakpoint-m) {
        display: none;
    }
}

.ms-account {
    &-welcome-tile {
        color: var(--msv-account-landing-title-font-color);
        display: block;
        margin-bottom: $msc-account-welcome-title-margin-bottom;
        margin-top: $msc-account-welcome-title-margin-top;
        padding-left: 15px;
        &__heading {
            @include font-content(500,
            var(--msv-account-landing-title-font-size), $msv-line-height-xl);
        }

        @media (min-width: $msv-breakpoint-m) {
            padding-left: 50px;
            &__heading {
                @include font-content(500,
                var(--msv-account-landing-title-desktop-font-size), $msv-line-height-xxl);
            }
        }
    }

    &-generic-tile {
        &__link {
            @include vfi();
            color: var(--msv-account-landing-link-color);
        }
    }
    &-wishlist-tile__heading {
        @include add-icon($msv-HeartFill, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-order-templates-tile__heading {
        @include add-icon($msv-file, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-address-tile__heading {
        @include add-icon($msv-MapPin, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-loyalty-tile__heading {
        @include add-icon($msv-IdCard, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-organization-users-tile__heading {
        @include add-icon($msv-OrganizationUsers, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-customer-balance-tile__heading {
        @include add-icon($msv-StoreAlt, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-invoice-tile__heading {
        @include add-icon($msv-invoice, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-generic-tile,
    &-wishlist-tile,
    &-order-templates-tile,
    &-address-tile,
    &-loyalty-tile,
    &-organization-users-tile,
    &-customer-balance-tile,
    &-invoice-tile {

        margin-bottom: $msc-account-welcome-title-margin-bottom;

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        &__heading {
            @include font-content(var(--msv-font-weight-medium), var(--msv-account-landing-tile-heading-size),
            $msv-line-height-l);
            color: var(--msv-account-landing-tile-heading-color);
            margin-bottom: $msc-generic-tile-heading-margin-bottom;
            text-transform: uppercase;
        }

        &__links {
            @include font-content-underline(var(--msv-font-weight-normal), var(--msv-account-landing-link-size),
            $msv-line-height-m);
            padding-top: $msc-account-welcome-title-links-padding-top;
        }

        &__link {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            padding-right: $msc-link-padding-right;
        }

        &__link-button {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            cursor: pointer;
        }

        &__description {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-tile-text-size),
            $msv-line-height-m);
            color: var(--msv-account-landing-tile-text-color);
        }

        &__value {
            @include font-content(var(--msv-font-weight-bold), var(--msv-account-landing-tile-text-size),
            $msv-line-height-m);
            color: var(--msv-account-landing-tile-text-color);

            &::before, &::after {
                content: " ";
            }
        }
    }
}
.order-tile {
    .ms-account-generic-tile__heading {
        @include add-icon($msv-DeliveryTruck, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }
}

.profile-tile {
    .ms-account-generic-tile__heading {
        @include add-icon($msv-Contact, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }
}