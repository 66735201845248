
$promo-banner-line-height: 58px;
$promo-banner-bottom-padding: 5px;
$promo-banner-top-padding: 5px;
$promo-banner-link-margin-left: 1em;

//style presets
:root {
    --msv-promo-banner-bg: #987f62; 
    --msv-promo-banner-border: #987f62; 
    --msv-promo-banner-font-color: var(--msv-font-secondary-color);
    --msv-promo-banner-link-color: var(--msv-font-secondary-color);
    --msv-promo-banner-font-size: 18px;
}

.ms-promo-banner {
    display: flex;
    flex-wrap: wrap;
    background: var(--msv-promo-banner-bg);
    border: 1px solid var(--msv-promo-banner-border);
    line-height: $promo-banner-line-height;
    width: 100%;
    text-transform: uppercase;

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    .msc-carousel__indicators {
        display: none;
    }

    .ms-promo-banner__text{
        color: var(--msv-promo-banner-font-color);
        font-size: var(--msv-promo-banner-font-size);
        font-style: normal;
        font-weight: var(--msv-font-weight-normal);
    }
    
    .ms-promo-banner__link {
        margin-left: $promo-banner-link-margin-left;
        font-size: var(--msv-promo-banner-font-size);
        text-decoration: underline;
        color: var(--msv-promo-banner-link-color);

    }

    .ms-promo-banner__carousel {
        width: 98%;
    }

    .msc-carousel__inner {
        .msc-carousel__item {
            &.active {
                text-decoration: none;
            }
        }
        color: var(--msv-font-primary-color);
    }

    .ms-promo-banner__close-button {
        color: var(--msv-font-secondary-color);
        background-color: transparent;
        border: none;
        cursor: pointer;

        &::after {
            @include msv-icon();
            content: $msv-x-shape;
        }

        .ms-promo-banner__close-button__label {
            display: none;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-promo-banner {
        line-height: 1.25em;
        padding: 8px 0;
        .ms-promo-banner__text, .ms-promo-banner__links a{
            font-size: 16px;
        }
        .ms-promo-banner__carousel {
            width: 95%;
        }
    }
}
