$msv-details-heading-margin-bottom: 32px;
$msv-details-heading-margin-top: 32px;
$msv-details-text-margin-right: 5px;
$msv-details-container-max-width: 1200px;
$msv-details-order-information-half-width: 50%;
$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-qr-code-margin-top: 20px;
$msv-details-order-information-qr-code-margin-bottom: 20px;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-qr-code-block-bg-color: $msv-white;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-border: 1px solid $msv-black;
$msv-details-group-padding: 20px 0;
$msv-details-group-pickup-border: 1px solid $msv-gray-300;
$msv-details-group-delivery-items-text-color: $msv-gray-500;
$msv-details-group-delivery-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-price-left: 210px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 100px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 12px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-payment-method-padding-m: 20px 0 10px 0;
$msv-details-default-padding: 20px 0;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-details-shipping-items-label-padding-top: 30px;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;
$msv-main-container-margin:3em auto;
$msv-font-weight-600: 600;
$msv-font-weight-355: 355;

.ms-order-confirmation {
    width: 100%;
    margin: auto;
    max-width: $msv-details-container-max-width;
    position: relative;

    .msc-cart-line__product-price{
        display: none;
    }

    .order-confirmation-order-summary-headings {
        display: flex;
        justify-content: space-between;
        .product-column-heading{
            width: 25%;
        }
        .color-column-heading{
            width: 20%;
        }
        .quantity-column-heading, .price-column-heading, .total-column-heading{
            width: 15%;
        }
        .size-column-heading{
            width: 10%;
        }

        > * {
            text-transform: uppercase;
            font-weight: 600;
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }

    .confirmationDetails {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: $msv-breakpoint-m) {
        flex-direction: column;
    }
    margin: 4em 0 2em;
        span.createdDate,
        .ms-order-confirmation__order-information-receipt-email {
            display: inline-block;
            width: auto;
            @include font-content-m();
            @media only screen and (max-width: $msv-breakpoint-m) {
                padding-right: 1em;
            }
            padding-right: 4em;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 23px;
            font-size: 16px;
            color: $nile-header-color;
            span {
                display:block;
                text-transform: none;
                font-weight: normal;
                color: var(--msv-font-primary-color);
            }
        }
    }

    span.paymentMethod{
        display: block; 
        clear: both;
        font-size: 18px;
        line-height: 1.25em;
        margin-bottom: 4em;
        position: relative;
        max-width: 50%;
        color: $nile-header-color;
        bottom: 105px;
        @media screen and (max-width: $msv-breakpoint-m) {
            position: relative;
            max-width: 100%;
            bottom: unset;
        }
    }
    h2.orderSummaryTitle{
        margin: 2em 0 1em 0;
        text-transform: uppercase;
        color: var(--msv-heading-color);
        font-size: 28px;
        @media only screen and (max-width: $msv-breakpoint-m) {
            margin: 32px 0;
        }
    }

    p.thankYouMessage{
        font-size: 18px;
        margin-bottom: 1em;
        margin-top: 32px;
        line-height: 1.25em;
    }
    h1.channelRef{
        padding-left: .25em;        
        text-transform: uppercase;
        display: inline-block;
        span.ms-order-confirmation__order-information-channel-reference-id{
            font-size: 0;
            span{
                @include font-content-xl(var(--msv-font-weight-heavy));
                color: $nile-header-color;
                font-size: 36px;
            }
        }
    }

    &__heading
    {
        @include font-content-xl(var(--msv-font-weight-heavy));
        color: $nile-header-color;
        margin-top: $msv-details-heading-margin-top;
        text-transform: uppercase;
        display: inline-block;
        font-size: 36px;
        line-height: 1.25em;
    }



    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;

        &-qr-code {
            align-items: center;
            background-color: $msv-details-order-information-qr-code-block-bg-color;
            display: flex;
            height: $msv-details-order-information-qr-code-block-height;
            justify-content: center;
            margin-top: $msv-details-order-information-qr-code-margin-top;
            margin-bottom: $msv-details-order-information-qr-code-margin-bottom;
            width: $msv-details-order-information-qr-code-block-width;

            .qrcode-screenreader-text {
                clip: rect(1px, 1px, 1px, 1px);
                clip-path: inset(50%);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        // &-channel-reference-id,
        &-sales-id,
        &-receipt-id
         {
            @include font-content-m();
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

            span {
                font-weight: $msv-font-weight-600;
            }
        }


        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            }
        }

        &-count {
            border-right: 1px solid $msv-black;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        margin: 2em 0;
        width: 100%;
        @include primary-button();
        text-transform: uppercase;        
        font-weight: bold;

    }

    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        padding: $msv-details-sale-lines-padding;

        &-shipping-items-label, &-picking-items-label, &-emailing-items-label {
            display: block;
            font-weight: $msv-font-weight-600;
        }

        &:first-child {
            border-top: none;
        }
    }

    &__groups {
        @include font-content-m();
        border-top: 1px solid #6e6e6e;
        display: flex;
        flex-wrap: wrap;
        line-height: 23px;
        font-size: 16px;
    }

    &__group {
        &-deliveryType-pickUp, &-deliveryType-ship, &-deliveryType-email {
            flex: 0 0 100%;
            display: flex;
            flex-direction: column;
        }

        &-delivery {
            padding: 2em 0 3em;
            border-bottom: 1px solid var(--msv-divider-color);

            &-heading {
                @include font-content-m(var(--msv-font-weight-heavy));
                margin-right: $msv-details-text-margin-right;
                font-weight: 500;
                text-transform: uppercase;
                color: $nile-header-color;
            }

            &-total-items {
                @include font-content-xl();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line {
        width: 100%;
        @media screen and (min-width: $msv-breakpoint-m) {
            border-bottom: 1px solid #e3e3e3;
            &:first-child{
            border-top: 1px solid #e3e3e3;
            }
            padding: 1em 0 1em ;
        }
        .msc-cart-line {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: start;

            &-product-details {
                width: 55%;
                @media screen and (max-width: $msv-breakpoint-m) {
                width: 80%;
                }
            }
            

            &__product {
                display: flex;
                flex-direction: row;
                min-width: max-content;
                width: calc(100% - 120px);
                justify-content: space-between;
                @media screen and (max-width: $msv-breakpoint-m) {
                    width: auto;
                    flex-direction: column;
                    &-individual-price{
                        display: none;
                    }
                }
            }
            &__product-title{
                width: calc(56% - 120px);
                @media screen and (min-width: $msv-breakpoint-m) {
                    
                    display: flex;
                }
            }
            &__product-variants{
                font-size: 16px;
                text-transform: lowercase;
                width: 66%;
                display: flex;
                justify-content: space-around;
                @media screen and (max-width: $msv-breakpoint-m) {
                    flex-direction: column;
                }
                .msc-cart-line__product-variant-item:first-child {
                    width: 67%;
                }

                .msc-cart-line__product-variant-item:last-child {
                    width: 33%;
                }

                .msc-cart-line__product-variant-size {
                    text-transform: uppercase;
                }

                .msc-cart-line__product-variant-size,
                .msc-cart-line__product-variant-color {
                    font-size: 0;
                    .name{
                        padding-left: 0;
                        font-size: 16px;
                    }

                }
            }
            &__product-image {
                margin-right: 20px;
                margin-bottom: 10px;
                img{
                    @media screen and (max-width: $msv-breakpoint-m) {
                        min-width: 100px;
                        
                    }
                }
            }

            &__product-title {
                font-size: $msv-text-size;
                line-height: $msv-text-line-height;
                text-transform: uppercase;
            }

            &__quantity {
                width: 15%;
                .quantity-label {
                    
                    white-space: nowrap;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: $msv-text-line-height;
                    display: none;
                }

                .quantity-value {
                    
                    &::before{
                        content: "× ";
                    }
                    color: $msv-gray-500;
                    display: inline;
                    font-size: 16px;
                    font-weight: normal;
                    margin-left: $msv-details-text-margin-right;
                    text-transform: uppercase;
                }
            }

            &__product-savings {
                padding-left: 0;
                font-size: 16px;
                font-weight: normal;
                //text-align: right;
                width: 30%;
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: $msv-breakpoint-m) {
                    width: 35%;
                    .msc-cart-line__product-savings-actual{
                        position: absolute!important;
                    }
                }
                .msc-price__actual{
                    font-size: 16px;
                    font-weight: normal;  
                }
            }

            &__quantity.single-quantity {
                visibility: visible;
            }

            &-item-product-discount {
                // display: none;
                color: var(--msv-success-color);
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;
            @include secondary-button();
        }
    }

    &__order-summary {
        @include font-content-m();
        float: right;
        padding: $msv-details-default-padding;
        width: 24%;

        &::after {
            clear: both;
        }

        &-heading {
            display: none;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            width: 100%;
        }

        &-line-total-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            padding-top: $msv-details-order-summary-line-spacing;
            width: 100%;
            font-weight: bold;
            color: $nile-header-color;
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-price {
            float: right;
            margin-bottom:0!important;
        }
    }

    &__help {
        @include font-content-m();
        padding: $msv-details-default-padding;

        &-title {
            @include font-content-m(var(--msv-font-weight-heavy));
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            @include vfi();
        }
    }

    &__payment-methods {
        @include font-content-m();
        padding: $msv-details-default-padding;
        width: 50%;
        float: left;

        &-title {
            @include font-content-m(var(--msv-font-weight-heavy));
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-space;
        }

        &-card-amount,
        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: 4px;
        }
    }

    &__address {
        margin-bottom: 1em;

        &-header {
            display: none;
        }

        &-city,
        &-threeletterisoregionname {
            &::before {
                content: "\A";
                white-space: pre;
            }
        }

        &-county {
            display: none;
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__pickup-date-timeslot {
        padding-top: 30px;
        margin-bottom: 6px;

        &-title {
            font-weight: $msv-font-weight-600;
            padding-bottom: 8px;
        }

        &-content {
            font-weight: $msv-font-weight-355;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

        margin: $msv-main-container-margin;
        &__sales-line{
        @media screen and (min-width: $msv-breakpoint-m) {
            .msc-price{
                top: 0;
                bottom: unset;
                margin-bottom: 0;
            }
        }
    }
        &__sales-lines {
            margin-bottom: 2em;
               
            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top;
            }

            &-emailing-items-label {
                margin-top: auto;
            }
        }

        &__address {
            &-phone {
                position: absolute;
            }
        }

        &__groups {
            & > div:nth-child(2n) + div::before {
                content: "";
                display: block;
                width: 200%;
                border-top: 1px solid $msv-gray-900;
            }
        }

        &__payment-methods {
            padding: $msv-details-payment-method-padding-m;
        }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__groups {
            display: block;

            & > div {
                border-top: 1px solid $msv-gray-900;
            }

            & > div:first-of-type {
                border: none;
            }

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;

            &-shipping-items-label, &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top-mobile;
            }
        }

        &__order-summary, &__payment-methods, &__help {
            float: none;
            padding-right: 0;
            width: 100%;
            position: relative;
            margin-left: 0;
        }

        &__help, &__order-summary {
            border-top: 1px solid $msv-gray-900;
        }

        &__order-summary {
            &-heading,
            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount,
            &-line-total-amount {
                max-width: 100%;
            }
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;
        }

        .msc-cart-line__product-price {
            position: unset;
        }
    }

    @include image(100px);
    .msc-empty_image {
        background-size: 100%;
    }
}

.horizonal {
    flex: auto !important;
    flex-direction: column !important;
}