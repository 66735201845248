$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 32px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 20px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-primary-list-address-detail-item-newline-margin-right: 0;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 40px;
$msv-address-list-heading-font-size: 32px;

$nile-grey-background: var(--msv-bg-accent-color);
$nile-line-divider-color: #edebe9;

//style presets
:root {
    --msv-address-font-size: var(--msv-body-font-size-l);
    --msv-address-font-color: var(--msv-font-primary-color);
    --msv-address-border: #{$msv-gray-300};
    --msv-address-font-family: #{$msv-primary-font-family};
    --msv-checkout-shipping-address-font-color: var(--msv-font-primary-color);

    //heading
    --msv-address-heading-font-size: #{$msv-address-list-heading-font-size};
    --msv-address-heading-font-color: #{$nile-header-color};
    --msv-address-primary-heading-font-size: var(--msv-body-font-size-m);
    --msv-address-primary-heading-font-color: var(--msv-font-primary-color);
    --msv-address-primary-text-font-size: var(--msv-body-font-size-s);
    --msv-address-primary-text-font-color: var(--msv-font-primary-color);

    // error
    --msv-address-error-bg: var(--msv-error-color);
    --msv-address-error-border: var(--msv-error-color);
    --msv-address-error-font-color: var(--msv-font-secondary-color);
    --msv-address-error-font-size: var(--msv-body-font-size-m);

    // link
    --msv-address-link: var(--msv-font-primary-color);
    --msv-address-link-size: var(--msv-body-font-size-m);

    // primary button
    --msv-address-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-address-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-address-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-address-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-address-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-address-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-checkout-billing-address__heading{
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2em;
    font-size: 16px;
}

.ms-checkout-billing-address__input.ms-checkout-billing-address__input-checkbox{
    @include form-input-checkbox();
    margin-right: 10px;
    min-height: 16px;
    min-width: 16px;
    margin-left: 0;
}
.ms-checkout-delivery-options__option-selected{
    padding-bottom: 1em;
    .ms-checkout-delivery-options{
        &__description, &__price.msc-price{

            font-size: 18px;
        }
    }
}


.ms-checkout-billing-address__shipping-address-checkbox-text{
    // font-size: 16px;
    margin-bottom: .5em;
    display: inline-block;
}

.msc-address-form {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__label {
        color: var(--msv-checkout-shipping-address-font-color);
        display: block;
        font-size: 14px;
        line-height: $msv-line-height-l;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    &__item {
        width: 48%;
        margin-bottom: 0;
        position: relative;
        padding-bottom: 28px;
        &.address-form__item-invalid input{
            border: 1px solid var(--msv-error-color);
        }
    }

    &__item-isprimary {
        display: flex;

        .msc-address-form__label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;
            font-size: 14px;

            &:hover {
                cursor: pointer;
            }
        }

        .msc-address-form__input-checkbox {
            width: 20px;
            @include form-input-checkbox();
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__item-county {
        display: none;
    }


    .MicrosoftMap {
        position: relative;
        width: 100%;

        .as_container_search {
            width: 100%;
        }
    }

    &__input,
    &__dropdown {
        @include vfi();
        color: var(--msv-checkout-shipping-address-font-color);
        display: block;
        width: 100%;
        border: 0.6px #E7E7E7 solid;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        box-sizing: border-box !important;
        border-radius: 2px;
        font-size: var(--msv-address-font-size);
        line-height: $msv-line-height-l;
        font-weight: var(--msv-font-weight-normal);
        height: $msv-account-management-address-textbox-height;
        padding: 0 6px;
        }

    &__dropdown[name="AddressTypeValue"] option{ 
        display: none;
        &[value="1"],
        &[value="2"]{
            display: initial
        }
    }
        

    &__alert {
        display: block;
    }

    &__button-save {
        @include primary-button(var(--msv-address-primary-btn-bg),
        var(--msv-address-primary-btn-font-color), var(--msv-address-primary-btn-border));
        margin-right: $msv-address-button-save-margin-right;
        margin-left: auto;
        @media screen and (max-width: $msv-breakpoint-m)
        {
            margin: 0 0 10px 0;
            width: 100%;
        }
    }

    &__button-cancel {
        @include secondary-button(var(--msv-address-secondary-btn-bg),
        var(--msv-address-secondary-btn-font-color), var(--msv-address-secondary-btn-border));
        text-transform: uppercase;
        @media screen and (max-width: $msv-breakpoint-m)
        {
            margin: 0 0 10px 0;
            width: 100%;
        }
    }

   

    .address-form__item-invalid &__alert {
        @include font-content(400,14px, $msv-line-height-m);
        font-family: var(--msv-address-font-family);
        text-transform: none;
        line-height: normal;
        position: relative;
        color: red;
        margin-top: 0;

        font-style: italic;
        position: absolute;
        bottom: 8px;

    }
}

.msc-address-detail {
    font-size: 18px;
    padding-bottom: 1em;
    &__item {
        margin-right: $msv-address-detail-item-margin-right;
    }

    &__item-newline {
        margin-right: 0;

        &::after {
            content: "\A";
            white-space: pre;
        }
    }

    &__item-address-detail_Phone {
        display: block;
    }

    &__main-item-empty {
        display: none;
    }

    &__item-phone {
        @include add-icon($msv-Phone);

        &-label {
            @include visually-hidden();
        }

        &::before {
            margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    clear: both;

    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
        @include form-input-radio();
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: $msv-address-select-item-margin-top;
        margin-bottom: $msv-address-select-item-margin-bottom;
        position: relative;

        .msc-address-detail {
            padding-left: $msv-address-select-item-address-detail-padding-left;
        }
    }

    &__input {
        @include vfi();
        @include form-input-checkbox();

    }

    &__button-add {
        @include vfi();
        @include primary-button(var(--msv-address-primary-btn-bg),
        var(--msv-address-primary-btn-font-color), var(--msv-address-primary-btn-border));
    }
}

// mixin

@mixin address-button {
    cursor: pointer;
}

.ms-account-management-address {
    margin-bottom: $msv-account-management-address-margin-bottom;

    .ms-address-list {
        margin-bottom: $msv-account-management-address-margin-bottom;
    }

    .msc-address-list__heading {
        @include font-content(500, var(--msv-address-heading-font-size), $msv-line-height-xl);
        color: var(--msv-address-heading-font-color);
        margin-bottom: 28px;
    }

    .msc-address-list__add-empty {
        margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
        margin-top: 100px;
        font-size: 16px;
    }

    .msc-address-list__button-add {
        @include primary-button(var(--msv-address-primary-btn-bg),
        var(--msv-address-primary-btn-font-color), var(--msv-address-primary-btn-border));
        text-transform: uppercase;
        font-weight: var(--msv-font-weight-bold);
    }

    .msc-address-list__primary {
        margin: 0 40px 0 0;
        padding: 20px;
        min-width: 240px;
        width: fit-content;
        @media screen and (max-width: $msv-breakpoint-s)
        {
            width: 100%;
        }
        .msc-address-list__primary-heading {
            @include font-content(var(--msv-font-weight-bold),
            var(--msv-address-primary-heading-font-size), $msv-line-height-m);
            color: var(--msv-address-primary-heading-font-color);
            margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;
        }

        .msc-address-list__primary-list {
            padding: 20px;
            border: 1px solid #987f62;

            @media screen and (max-width: $msv-breakpoint-s)
            {
                width: 100%;
            }
            &::nth-child(2) {
                padding-top: 0;
            }

            .msc-address-detail {
                clear: both;

                @include font-content(var(--msv-font-weight-normal),
                var(--msv-address-primary-text-font-size), $msv-line-height-s);
                color: var(--msv-address-primary-text-font-color);

                .msc-address-detail__item {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
                }

                .msc-address-detail__item-newline {
                    margin-right: $msv-primary-list-address-detail-item-newline-margin-right;

                    &::after {
                        content: "\A";
                        white-space: pre;
                    }
                }
            }

            .msc-address-list__button-primary {
                @include secondary-button(var(--msv-address-secondary-btn-bg),
                var(--msv-address-secondary-btn-font-color), var(--msv-address-secondary-btn-border));
                display: block;
                margin-top: $msv-account-management-address-list-button-primary-margin-top;
                @media screen and (max-width: $msv-breakpoint-s)
                {
                    width: 100%;
                }
            }

            .msc-address-list__button-edit {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0;
                padding: 6px 12px 6px 0;
                text-decoration: underline;
            }

            .msc-address-list__button-remove {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0;
                padding: 6px 12px 6px 0;
                text-decoration: underline;
            }
        }

    }
}

.remove-address-screen-reader {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.address-divider {
    display: block;
    width: 100%;
    height: 10px;
    border-top: 1px solid $nile-line-divider-color;
    margin-top: 2rem;
}

.primary-list {
    .msc-address-list__primary-list {
        min-width: 241px;
        padding-bottom: 2rem;
    }
}
.other-addresses {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;

    .msc-address-list__primary-heading{
        flex-basis: 100%;
    }
    .msc-address-list__primary-list {
        border: none!important;
        background: $nile-grey-background;

    }
}