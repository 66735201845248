$video-player-width: 100%;
$shadow-color: "#000000";
// Style presets
:root {
    --msv-video-player-width: #{$video-player-width};
    --msv-video-title-color: #FFF;
    --msv-video-title-drop-shadow-opacity: "#00000045";
}


.msc-videoplayer .msc-videoplayer__wrapper{
    max-height: 100vh;
    display: flex;
    overflow: hidden;
    align-items: center;

}
.ms-video-player__details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    &__title{
        font-size: 6em;
        color: var(--msv-video-title-color);
        text-shadow: 0px 0px 12px var(--msv-video-title-drop-shadow-opacity);
        text-transform: uppercase;
        opacity: 1;
        line-height: 1em;
        text-align: center;
        margin-bottom: .5em;
    }
    
    > div {
        display: block;
        margin: auto;
        text-align: center;
    }
    a.ms-video-player__details__cta__link{
        text-transform: uppercase;
        font-weight: 600;
        color: #FFFFFF;
        background-color: var(--msv-content-block-button-color);
        font-size: 1.25em;
        line-height: 24px;
        text-decoration: none;
        border: 1px solid var(--msv-content-block-button-color);
        margin: 0;
        padding: 12px 32px;
        transition: all .3s ease;
        &:hover{
            background-color: #FFFFFF;
            color: var(--msv-content-block-button-color);
            border: 1px solid #FFFFFF;
        }
    }
}



.ms-video-player {
    
    .msc-video-player {
        width: var(--msv-video-player-width);

    }
    .nile-scroll-down {
        display: none;
    }
    @media screen and (min-width: $msv-breakpoint-xs) {
        .nile-scroll-down {
            transition: color .3s ease;
            display: block;
            margin-top: 20px;
            visibility: hidden;
            width: fit-content;
            color: var(--msv-font-secondary-color);
            position: fixed;
            bottom: 20px;
            text-shadow: 0px 0px 12px #00000045;
            left: 50%;
            transform: translateX(-50%);
            min-width: 71 px;
            &::after {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-weight: 900;
                text-rendering: auto;
                color: inherit;
                font-size: 82px;
                line-height: 40px;
                font-family: $msv-icon-font-family;
                content: $msv-ChevronDown;
                width: fit-content;
                visibility: visible;
                position: absolute;
                text-shadow: inherit;
                left: 0;
                bottom: 0;
            }
            &:hover {
                color: $msv-primary;
                cursor: pointer;
            }
        }
    }
}
