$msv-account-profile-heading-margin-bottom: 32px;

$msv-account-profile-section-margin-right: 100px;
$msv-account-profile-section-margin-right-m: 0;
$msv-account-profile-section-margin-bottom: 30px;
$msv-account-profile-section-heading-margin-bottom: 4px;
$msv-account-profile-section-description-margin-bottom: 6px;

$msv-account-profile-preference-margin-top: 20px;
$msv-account-profile-preference-heading-margin-bottom: 10px;
$msv-account-profile-preference-description-margin-bottom: 0;

$msv-account-profile-toggle-button-icon-size: 28px;
$msv-account-profile-toggle-button-padding: 0 5px;

$msv-account-profile-attributes-min-width: 260px;
$msv-account-profile-attributes-section-margin-bottom: 12px;
$msv-account-profile-attributes-tile-heading-margin-bottom: 10px;
$msv-account-profile-attributes-tile-heading-icon-padding: 5px;
$msv-account-profile-attributes-toggle-min-height: 50px;
$msv-account-profile-attributes-toggle-button-height: 30px;
$msv-account-profile-attributes-toggle-button-disable-opacity: 0.6;
$msv-account-profile-attributes-button-right-margin: 10px;


//style presets
:root {
    --msv-account-profile-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-font-color: var(--msv-font-primary-color);

    // heading
    --msv-account-profile-heading-font-color: var(--msv-font-primary-color);
    --msv-account-profile-heading-font-size: var(--msv-body-font-size-l);
    --profile-page-heading-font-color: #{$nile-header-color};

    // title
    --msv-account-profile-title-font-size: var(--msv-body-font-size-xl);

    // button
    --msv-account-profile-toggle-btn-font-color: var(--msv-accent-brand-color);
    --msv-account-profile-toggle-btn-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-toggle-size: #{$msv-account-profile-toggle-button-icon-size};

    // secondary button
    --msv-checkout-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-checkout-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-checkout-secondary-btn-border: var(--msv-accent-brand-color);

    // primary button
    --msv-checkout-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-checkout-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-checkout-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-account-profile {
    @include font-content(var(--msv-font-weight-normal), var(--msv-account-profile-font-size), $msv-line-height-m);
    color: var(--msv-account-profile-font-color);
    align-items: flex-start;
    display: inline-flex;
    flex-direction: unset;
    justify-content: center;

    .ms-account-loyalty-tile__heading {
        text-transform: none;
        color: var(--profile-page-heading-font-color);
        font-size: 32px;
        padding-bottom: 20px;

        &::before {
            content: none !important;
        }
    }

    &__edit-button {
        // @include primary-button(var(#987f62), var(white), var(#987f62));
        background-color: #987f62;
        //border-color: #987f62;
        cursor: pointer;
        color: #ffffff;
        float: right;
        font-size: 16px;
        width: auto;
        font-weight: 500;
        line-height: 1em;
        transition: .3s ease;
        padding: 10px 20px;
        margin-left: 10px;
        box-shadow: none !important;
        border: solid 1px #987f62 !important;
        height: 48px;

        &:active {
            border: solid 1px black !important;
        }

        &:hover {
            background-color: var(--msv-primary-button-background-hover-color);
            color: var(--msv-primary-button-text-hover);
            text-decoration: var(--msv-primary-button-text-hover-deco);
        }

        // &::after {
        //     @include msv-icon();
        //     padding-left: .5em;
        //     content: $msv-ChevronRight;
        // }
    }

    &__cancel-button {
        // @include secondary-button(var(--msv-checkout-secondary-btn-bg),
        //         var(--msv-checkout-secondary-btn-font-color),
        //         var(--msv-checkout-secondary-btn-border));
        background-color: --msv-checkout-secondary-btn-bg;
        border: solid 1px #987f62 !important;
        cursor: pointer;
        color: #987f62;
        float: right;
        font-size: 16px;
        width: auto;
        font-weight: 500;
        line-height: 1em;
        transition: .3s ease;
        padding: 10px 20px;
        margin-left: 10px;
        box-shadow: none !important;
        height: 48px;

        &:active {
            border: solid 1px black !important;
        }
    }

    &__date-entry {
        width: 12rem;
        height: 3rem;
        font-size: 18px;
        padding: 10px;
        text-align: center;
    }

    &__flex-section {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__error {
        color: red;
    }

    &__success {
        color: #987f62;
    }

    &-wrapper {
        margin-right: $msv-account-profile-section-margin-right;
    }

    &__heading {
        @include font-content(500,
            var(--msv-account-profile-title-font-size), $msv-line-height-xl);
        margin-bottom: $msv-account-profile-heading-margin-bottom;
    }

    &__section {
        margin-bottom: $msv-account-profile-section-margin-bottom;

        &-heading {
            @include font-content(var(--msv-font-weight-heavy),
                var(--msv-account-profile-heading-font-size), $msv-line-height-l);
            color: var(--msv-account-profile-heading-font-color);
            margin-bottom: $msv-account-profile-section-heading-margin-bottom;
        }

        &-description {
            margin-bottom: $msv-account-profile-section-description-margin-bottom;
        }

        &-links {
            text-decoration: underline;
        }

        &-link {
            @include vfi();
            color: var(--msv-account-profile-font-color);
        }
    }

    &__preference {
        margin-top: $msv-account-profile-preference-margin-top;

        &-heading {
            @include font-content(var(--msv-font-weight-normal),
                var(--msv-account-profile-font-size), $msv-line-height-m);
            color: var(--msv-account-profile-font-color);
            margin-bottom: $msv-account-profile-preference-heading-margin-bottom;
        }

        &-description {
            margin-bottom: $msv-account-profile-preference-description-margin-bottom;
        }
    }

    &__toggle-wrapper {
        font-size: var(--msv-account-profile-toggle-btn-font-size);
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__toggle-button {
        font-size: var(--msv-account-profile-toggle-size);
        background-color: transparent;
        padding: $msv-account-profile-toggle-button-padding;

    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-enable-button {
        @include add-icon($msv-ToggleRight);
        color: var(--msv-account-profile-toggle-btn-font-color);
    }

    &__toggle-disable-button {
        @include add-icon($msv-ToggleRight);

        &::before {
            transform: scale(-1, 1);
        }
    }

    .ms-account-profile__attributes {
        min-width: $msv-account-profile-attributes-min-width;

        &-tile__heading {
            @include add-icon($msv-ListAlt, before);
            @include font-content(var(--msv-font-weight-bold),
                var(--msv-account-landing-tile-heading-size), $msv-line-height-l);
            color: var(--msv-account-profile-heading-font-color);
            margin-bottom: $msv-account-profile-attributes-tile-heading-margin-bottom;

            &::before {
                padding-right: $msv-account-profile-attributes-tile-heading-icon-padding;
            }
        }

        &__section {
            margin-bottom: $msv-account-profile-attributes-section-margin-bottom;

            &-heading {
                font-size: var(--msv-account-profile-font-size);
                font-weight: var(--msv-font-weight-normal);
                color: var(--msv-account-profile-heading-font-color);
                margin-bottom: $msv-account-profile-section-heading-margin-bottom;
            }

            &__toggle {
                min-height: $msv-account-profile-attributes-toggle-min-height;
            }

            &__toggle-wrapper {
                font-size: var(--msv-account-profile-toggle-btn-font-size);
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            &__toggle-button {
                font-size: var(--msv-account-profile-toggle-size);
                background-color: transparent;
                padding: $msv-account-profile-toggle-button-padding;
                height: $msv-account-profile-attributes-toggle-button-height;

            }

            &__toggle-button.disabled {
                opacity: $msv-account-profile-attributes-toggle-button-disable-opacity;
            }

            &__toggle-enable {
                .ms-account-profile__attributes__section__toggle-enable-text {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            &__toggle-disable {
                .ms-account-profile__attributes__section__toggle-disable-text {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            &__toggle-enable-button {
                @include add-icon($msv-ToggleRight);
                color: var(--msv-account-profile-toggle-btn-font-color);
            }

            &__toggle-disable-button {
                @include add-icon($msv-ToggleRight);

                &::before {
                    transform: scale(-1, 1);
                }
            }
        }

        &-error-label {
            color: var(--msv-error-color);
        }

        &_input,
        &_select {
            width: 100%;
        }

        &_input-edit-false,
        &_select-edit-false {
            border: none;
            background: transparent;
            opacity: 1;
            color: var(--msv-account-profile-font-color);
        }

        &_select-edit-false {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
            opacity: 1;
            color: var(--msv-account-profile-font-color);
        }

        &-edit-button,
        &-save-button,
        &-cancel-button {
            @include vfi();
            color: var(--msv-account-profile-font-color);
            background-color: transparent;
            font-size: var(--msv-body-font-size-m);
            text-decoration: underline;
            margin-right: $msv-account-profile-attributes-button-right-margin;
            padding: 0;
            height: auto;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        display: flex;
        flex-direction: column;

        &-wrapper {
            margin-right: $msv-account-profile-section-margin-right-m;
        }
    }
}

@media screen and (max-width: 459px) {
    // .ms-DatePicker-monthPicker .monthPicker_2467ec8b {
    //     display: block !important;
    // }

    .ms-DatePicker-optionGrid.optionGrid_2467ec8b {
        height: auto;
    }

    .goToday_2467ec8b {
        top: 0px !important;
        left: 0px !important;
        right: auto !important;
    }

    .dayPicker_2467ec8b {
        padding-top: 10px;
    }

    .calendarsInline_2467ec8b .monthPicker_2467ec8b,
    .calendarsInline_2467ec8b .yearPicker_2467ec8b {
        padding-top: 55px;
        display: block !important;
    }

    .ms-DatePicker-wrap {
        flex-direction: column !important;
    }

    .ms-DatePicker-monthOption {
        width: 30px !important;
        height: 30px !important;
    }

    .ms-DatePicker-yearOption {
        width: 30px !important;
        height: 30px !important;
    }
}

// .ms-TextField-fieldGroup.fieldGroup-46 {
//     height: 48px !important;
// }

// .ms-TextField-fieldGroup.fieldGroup-58 {
//     height: 48px !important;
// }

.ms-TextField-fieldGroup {
    height: 48px !important;
}

.ms-DatePicker-nextYear {
    @include add-icon($msv-ChevronRight);
}

.ms-DatePicker-nextMonth {
    @include add-icon($msv-ChevronRight);
}

.ms-DatePicker-prevMonth {
    @include add-icon($msv-ChevronLeft);
}

.ms-DatePicker-prevYear {
    @include add-icon($msv-ChevronLeft);
}

.fieldGroup-57 {
    height: 48px !important;
}

.ms-TextField-field {
    height: 48px !important;
}

.ms-TextField.textField-45 {
    height: 48px !important;
}

.ms-DatePicker-prevDecade {
    @include add-icon($msv-ChevronLeft);
}

.ms-DatePicker-nextDecade {
    @include add-icon($msv-ChevronRight);
}

.ms-TextField.is-active {
    height: 48px !important;
}

.js-showYearPicker {
    color: #987f62 !important;
}

.ms-DatePicker-currentDecade {
    color: #987f62 !important;
}

.currentDecade_2467ec8b,
.currentYear_2467ec8b {
    color: #987f62 !important;
    text-decoration: underline;
}

.icon-56 {
    @include add-icon("\f133");
    font-size: 20px;
    line-height: 30px;
}

.ms-account-profile__flex-section div div.ms-DatePicker {
    min-width: 250px;
}