//style presets
:root {
    --msv-breadcrumb-item-font-color: var(--msv-font-primary-color);
    --msv-breadcrumb-item-active-font-color: var(--msv-accent-brand-color);
    --msv-breadcrumb-item-font-size: 16px;
}

.ms-breadcrumb {
    margin-top: 42px;
    padding-bottom: 42px;
    line-height: 26px;
    .ms-breadcrumb_list {
        color: var(--msv-breadcrumb-item-font-color);
        float: left;
        list-style: none;
        padding: 0;

        .ms-breadcrumb-back-button {
            text-decoration: none;
            cursor: pointer;
            float: left;
            margin-right: 10px;
            text-transform: uppercase!important;
            font-size: 16px;
            font-weight: bold;

            @include add-icon($msv-ChevronLeft, before);

            &::before {
                padding-right: 12px;
            }

            &:hover{
                text-decoration: underline;
            }
        }
    }

    .ms-breadcrumb_item {
        color: var(--msv-breadcrumb-item-font-color);
        float: left;
        font-size: var(--msv-breadcrumb-item-font-size);
        position: relative;
        margin: 0;
        padding-right: 5px;
        @include font-content-s(500);

        a[data-no-click="true"] {
            cursor: default;
            pointer-events: none;
        }

        .ms-breadcrumb_link.ms-breadcrumb-no-link-text {
            display: inline-block;
            vertical-align: middle;
        }

        .ms-breadcrumb_link {
            color: var(--msv-breadcrumb-item-font-color);
            font-size: var(--msv-breadcrumb-item-font-size);
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                text-decoration: underline;
            }
        }

        &.breadcrumb_item-current {
            .ms-breadcrumb_link {
                text-decoration: none;
                color: var(--msv-breadcrumb-item-active-font-color);
            }
        }
    }
}
