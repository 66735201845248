$fair-work-color: #d5ada1;
$eco-friendly-color: #96a89c;
$clean-water-color: #93b4c2;
$all-around-color: #bdb5af;
$static-page-narrow-width: 70%;
$general-fonts: "brandon normal", sans-serif;
$light-theme-color: #ffff;
$font-size-default: 18px;
* {
    padding: 0px;
}
/*Fair Work Banner Colour*/
.Fair_Work {
    background-color: $fair-work-color;
}
/*Eco_Friendly banner colour*/
.Eco_Friend {
    background-color: $eco-friendly-color;
}
/*Clean water banner colour*/
.Clean-Water {
    background-color: $clean-water-color;
}
/*All Around banner colour*/
.All-Around {
    background-color: $all-around-color;
}
// campaign page title block Desktop-view
.campaign_title {
    margin-bottom: 30px;
    margin-top: 100px;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: solid 2px #6e6e6e;
    padding-bottom: 15px;
    font-weight: bolder;
    //Font size
    p {
        font-size: 2.2rem;
    }
}

//Motto tile
.motto-tile {
    margin-top: 0px;
    height: 100%;
    background-color: $fair-work-color;
    //Motto tile Fonts
    .motto-tile p {
        color: $light-theme-color;
        font-size: 20px;
        text-align: center;
        margin: 10px;
    }
}
//quote tile style
.ms-content-block[data-m-layout="side-to-side"].read-more-tile .ms-content-block__details{
    
    height: 100%;
    margin:auto;
    padding: 20px;
    p{
        font-size: 22px;
        font-weight: 500;
    }
}
.creation-tile {
    margin-bottom: 20px;
    width: calc(100% - 12px);
}
.creation-full-tile {
    margin-top: 10px;
}

@media (min-width: $msv-breakpoint-xl) {
    button#flipperNextButton_product-collection {
        top: 50%;
        right: -50px;
    }
    button#flipperPreviousButton_product-collection {
        top: 50%;
        left: -50px;
    }
}



.text-block,
.design-contain,
.sustainability-container .row,
.text-tile
{
    width: $static-page-narrow-width;
    margin-left: auto;
    margin-right: auto;
}

.loud .ms-product-collection__heading{
    font-size: 42px;
    margin-top: 2em;
    margin-bottom: 22px;
    line-height: 1.15em;
    font-weight: 600;
    text-transform: uppercase;
    color: #333333;
}

.text-block {
    &.loud{
      max-width: 1000px;  
      margin-top: 140px;
      margin-bottom: 160px;
      display: flex;
    //   min-height: 300px;
      align-items: center;
      &.bottomless{
          margin-bottom: none;
      }
      h1{
          font-size: 58px;
          line-height: 1.15em;
          color: #333;
      }
    }
    h2 {
        font-size: 32px;
        font-weight: 500;
        letter-spacing: -1px;
        color: #333;
    }
    h3 {
        font-weight: 600;
        font-size: $font-size-default;
    }
    h4 {
        font-weight: normal;
        font-size: $font-size-default;
    }
    p,
    .ms-content-block .ms-content-block__details .ms-content-block__cta a {
        
        font-size: 26px;
    }
}
.text-block {
    ul {
        padding-left: 1.5em;
        li {
            font-size: $font-size-default;
            padding-left: 0.5em;
        }
    }
}

// table container same size as parent
.text-block {
    &.table_container {
        width: 100%;

        text-align: left;
    }
}

//hide slider controller/indicators
.slider .msc-carousel__indicators,
.msc-carousel__indicators {
    visibility: hidden;
}

/*Banner Css*/
.Center_container {
    width: 50%;
    margin: auto;
}
/* Banner Image Block*/
.banner-tile .ms-content-block__image {
    /*Blend the image with the background color.*/
    mix-blend-mode: multiply;
    vertical-align: middle;
    display: inline-block;
    width: 50%;
}
/*Banner Image*/
.banner-tile .msc-main_image {
    /*Allocating height and setting the image to center*/
    height: 80px;
    margin: auto;
}
.banner .row {
    width: 50%;
    margin: auto;
}
/*content block*/
.banner .ms-content-block[data-m-layout="tile"] {
    width: 100%;
    min-height: 80px;
}
/*Content Block*/
.banner .ms-content-block[data-m-layout="tile"] .ms-content-block__details {
    /*Display side by side image with white as text color*/

    display: inline-block;
    width: 50%;

    color: $light-theme-color;
    text-align: left;
    font-weight: bold;

    letter-spacing: 0.25px;
}
/*Banner Tile*/
.banner-tile {
    /*sticking all banner tile together.*/
    display: inline-block;
}
/*stores*/
.ms-accordion-item .drawer {
    border-top: none;
    
    .drawer__button{
        height: auto;
        padding: 0px;
        
        margin: 0px;
        justify-content: start;
        flex-direction: row-reverse;
    }
    .drawer__buttontext{
        padding-left: 15px;
    }
    .ms-accordion-item-content{
        padding-left: 30px;
    }
    
}


/*	css for window size below 770px*/
@media (max-width: $msv-breakpoint-m) {
    #video-player{
        margin: 0 -15px;
    }
    
    .ms-video-player__details__title{
        font-size: 3em;
    }
    .banner .ms-content-block[data-m-layout="tile"] {
        padding-bottom: 0px;
    }
    /*adjust width of the image according to different sccreen sizes*/
    .banner .ms-content-block[data-m-layout="tile"] .ms-content-block__image img {
        width: fit-content;
    }
    .banner-tile .msc-main_image {
        position: relative;
        height: 4em;
        margin-left: 25%;
        width: 90%;
    }
    .banner .ms-content-block__details p {
        line-height: 40px;
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
    }
    .banner .row {
        width: 100%;
        margin: auto;
    }
    .text-block {
        &.table_container {
            .row {
                flex-wrap: nowrap;
                .col-md-4 {
                    width: auto;
                    margin-right: 20%;
                }
            }
        }
    }
    #main {
        width: 100%;
        .header_media {
            width: 100%;
            margin: 0;
        }
    }

    .text-block,
    .design-contain,
    .sustainability-container .row,
    .text-tile,
    .text-block .row,
    body .container,
    .Center_container {
        width: 100%;
        padding: 0px;
    }
    .campaign_title p {
        font-size: 1.3rem;
    }

    .creation-tile {
        display: block;
        width: 100%;

        height: calc(100% - 20px);
        margin-top: 20px;
    }
    .ms-text-block &.motto-tile &.creation-tile {
        margin-top: 0px;
        height: 100%;
    }
}


// 
