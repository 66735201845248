$msc-promo-code-discount-heading-margin-top: 20px;
$msc-promo-code-discount-line-container-margin-top: 4px;
$msc-promo-code-discount-line-container-padding-bottom: 10px;
$msc-promo-code-discount-line-remove-btn-padding: 0 12px;
$msc-promo-code-alert-padding: 8px;
$nile-promo-code-header-font-size: 16px;

:root {
    --msv-promo-code-heading-color: var(--msv-font-primary-color);
    --msv-promo-code-heading-size: $nile-promo-code-header-font-size;
    --msv-promo-code-link-color: var(--msv-font-primary-color);
    --msv-promo-code-link-size: var(--msv-body-font-size-m);
    --msv-promo-code-applied-discount-color: var(--msv-font-primary-color);
    --msv-promo-code-applied-discount-size: var(--msv-body-font-size-m);
    --msv-promo-code-border: var(--msv-font-primary-color);
    --msv-promo-code-form-input-bg: #{$msv-white};
    --msv-promo-code-form-input-font-color: var(--msv-font-primary-color);
    --msv-promo-code-form-input-border: var(--msv-btn-input-border-color);
    --msv-promo-code-form-input-size: var(--msv-body-font-size-l);
    --msv-promo-code-alert-bg: #{$msv-pink};
    --msv-promo-code-alert-color: var(--msv-error-color);
    --msv-promo-code-alert-border: var(--msv-error-color);
    --msv-promo-code-alert-size: var(--msv-body-font-size-m);
}

.msc-promo-code {
    &__group{
        display: flex;
        button{
            height: 40px;
            min-width: 35px;
        }
    }
    &-heading.msc-promo-code-heading {
        font-size: var(--msv-order-summary-font-size);
        color: var(--msv-promo-code-heading-color);
        text-transform: uppercase;
    }

    &__discount {
        display: flex;
        justify-content: space-between;
        margin-top: $msc-promo-code-discount-heading-margin-top;

        &-heading {
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-promo-code-applied-discount-size), $msv-line-height-m);
            color: var(--msv-promo-code-applied-discount-color);
        }

        &-value {
            font-weight: var(--msv-font-weight-bold);
            display: none;
        }
    }

    &__line {
        &-container {
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-promo-code-applied-discount-size), $msv-line-height-m);
            color: var(--msv-promo-code-applied-discount-color);
            border-bottom: 2px solid #a8a7a7;
            margin-top: $msc-promo-code-discount-line-container-margin-top;
            padding-bottom: $msc-promo-code-discount-line-container-padding-bottom;
        }

        &-value {
            display: inline-block;
        }

        &__btn-remove {
            @include button-link(var(--msv-promo-code-link-color));
            @include font-content(var(--msv-font-weight-bold), var(--msv-promo-code-link-size), $msv-line-height-m);
            height: auto;
            padding: $msc-promo-code-discount-line-remove-btn-padding;
        }
        &-discount-value span.msc-price__actual{
            margin-left: 0;
        }
    }

    &__input-box {
        @include form-input-el(var(--msv-promo-code-form-input-bg),
        var(--msv-promo-code-form-input-font-color), var(--msv-promo-code-form-input-border));
        @include vfi();
        border: none;
        font-size: var(--msv-promo-code-form-input-size);
        flex: auto;
        margin-right: 10px;
        height: 40px;
        padding-left: 8px;
        font-size: 14px;
    }
}

.msc-alert-danger {
    background-color: transparent;
    border: none;
    color: var(--msv-error-color);
    font-size: var(--msv-promo-code-alert-size);
    padding: $msc-promo-code-alert-padding 0;
    font-style: italic;
    text-transform: none;
}
