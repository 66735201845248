$product-collection-item-width: 250px;
$product-collection-carousel-padding-top: 0;
$product-collection-margin-bottom-mobile: 48px;
$product-collection-margin-bottom-desktop: 100px;
$product-collection-heading-padding-right: 100px;
$product-collection-heading-margin-top: 20px;
$product-collection-heading-margin-bottom: 8px;
$product-collection-item-padding: 5px 0 24px;
$product-collection-price-strikethrough-line-height: $msv-line-height-m;
$product-collection-tooltip-left: 0;
$product-collection-tooltip-top: 0;
$product-collection-tooltip-inner-margin-top: -36px;
$product-collection-tooltip-inner-max-width: 200px;
$product-collection-tooltip-inner-padding: 4px 8px;
$product-collection-transparent: transparent;

//style presets
:root {
    --msv-product-collection-font-family: #{$msv-primary-font-family};

    // heading
    --msv-product-collection-heading-font-size: var(--msv-heading-font-size-s);
    --msv-product-collection-heading-font-color: var(--msv-font-primary-color);
    --msv-product-collection-text-size: var(--msv-body-font-size-m);
    --msv-product-collection-text-color: var(--msv-font-primary-color);

    // rating star
    --msv-product-collection-rating-star: var(--msv-font-primary-color);

    //flipper
    --msv-product-collection-flipper-bg: #{$product-collection-transparent};
    --msv-product-collection-flipper-font-color: var(--msv-accent-brand-color);
    --msv-product-collection-flipper-border: #{$product-collection-transparent};

    // Tooltip
    --msv-product-collection-tooltip-bg: var(--msv-font-primary-color);
    --msv-product-collection-tooltip-font-color: var(--msv-font-secondary-color);
}

.ms-product-collection {
    .msc-ss-carousel-strip{
        .msc-swatch-container:nth-child(2) button{
            display: none;
        }
    }

    &.quarters{
        margin-bottom: 0;
        ul.ms-product-collection__items li.ms-product-collection__item{
            padding-bottom: 16px;
            
            @media (min-width: $msv-breakpoint-m) {
            width: calc(25% - 12px);
            }
            width: calc(50% - 12px);
            .msc-product__image{
                img{
                    width: 100%;
                    height: auto;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
        li.msc-ss-carousel-item.ms-product-collection__item{
            padding-bottom: 16px;
            width: calc(50vw - 24px);
            max-width: calc(1730px / 4); // max container width = 1800; 10 px between columns x 3 + 20px padding on both sides = 70px total spacing; 1800 - 70 = 1730;
            .msc-product__image{
                height: calc((50vw - 24px) * 1.333); // min height is based on 3:4 ratio
                max-height: 475px;
                @media (min-width: $msv-breakpoint-m) {
                    height: calc((25vw - 24px) * 1.333); // min height is based on 3:4 ratio
                    max-height: 575px;
                    }
                .ms-quickView{
                    &__button{
                        height: 56px;
                        font-size: 20px;
                    }
                    bottom: 0;
                    top: unset;
                    position: absolute;
                    margin-bottom: 0;
                }
                position: relative;
            }
            @media (min-width: $msv-breakpoint-m) {
                width: calc(25vw - 24px);
                }
            .msc-product__image img{
                width: 100%;
                height: auto;
            }

        }
        .msc-product {
            .msc-product__title{
                font-size: 18px;
                padding-top: .5em;
            }
        }
        .msc-price{
            font-size: 16px;
        }
        
    }

    margin-bottom: $product-collection-margin-bottom-mobile;

    @media (min-width: $msv-breakpoint-m) {
        margin-bottom: $product-collection-margin-bottom-desktop;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-normal),
        var(--msv-product-collection-heading-font-size), $msv-title-line-height-xs);
        color: var(--msv-product-collection-heading-font-color);
        margin-top: $product-collection-heading-margin-top;
        margin-bottom: $product-collection-heading-margin-bottom;
        padding-right: $product-collection-heading-padding-right;
        text-transform: none;
    }

    &__item {
        display: inline-block;
        max-width: none;
        // padding: $product-collection-item-padding;
        margin-right: 12px;
        overflow: hidden;
        text-align: left;
        vertical-align: top;
        width: $product-collection-item-width;
        position: relative;
        
        &:hover, &:focus, &:active {
            .ms-quickView {
                &__button {
                    display: block;
                }
            }
        }
    }

    .msc-ss-carousel {
        padding-top: $product-collection-carousel-padding-top;

        .msc-flipper {
            color: var(--msv-product-collection-flipper-font-color);
            background-color: var(--msv-product-collection-flipper-bg);
            border: 1px solid var(--msv-product-collection-flipper-border);

            &:hover {
                color: var(--msv-product-collection-flipper-font-color);
            }
        }
    }

    .msc-product {
        @include vfi();
        display: block;
        outline-offset: 0;
        overflow: hidden;
        color: #525252;
        transition: all .3s ease;
        &:hover{
            text-decoration: none;
            color: $msv-primary;
        }

        .msc-product__title {
            @include font-content(var(--msv-font-weight-bold),
            var(--msv-product-collection-text-size), $msv-line-height-m);
            color: #525252;
            text-decoration: none;
            text-transform: uppercase;
            transition: all .3s ease;

            &:hover {
                text-decoration: none;
                color: $msv-primary;
            }
        }
    }

    .msc-price {
        color: var(--msv-product-collection-text-color);
        display: inline-block;
        font-family: var(--msv-product-collection-font-family);
        font-size: var(--msv-body-font-size-s);
        line-height: $msv-line-height-l;
        text-transform: none;

        
        &[aria-hidden='true'] > .msc-price__actual{
            color: var(--msv-aad-generic-error-color);
        }
        
        &__strikethrough {
            font-size: var(--msv-body-font-size-m);
            line-height: $product-collection-price-strikethrough-line-height;
        }

        &__actual {
            font-weight: var(--msv-font-weight-normal);
        }
    }

    .msc-rating {
        &__star,
        &__count {
            color: var(--msv-product-collection-rating-star);
            border-bottom: none;
        }

        &__half-star {
            &::after {
                color: var(--msv-product-collection-rating-star);
            }
        }
    }

    .msc-tooltip {
        display: block;
        left: $product-collection-tooltip-left;
        position: absolute;
        top: $product-collection-tooltip-top;

        .msc-tooltip-inner {
            background-color: var(--msv-product-collection-tooltip-bg);
            color: var(--msv-product-collection-tooltip-font-color);
            font-size: var(--msv-body-font-size-s);
            margin-top: $product-collection-tooltip-inner-margin-top;
            max-width: $product-collection-tooltip-inner-max-width;
            padding: $product-collection-tooltip-inner-padding;
            text-align: center;
        }

        &.msc-bs-tooltip-bottom {
            padding: $tooltip-arrow-height 0;

            .msc-tooltip-inner {
                margin-top: 0;
            }
        }
    }
}
