@import "../00-settings/colors";
@import "../00-settings/font";
@import "../00-settings/responsive-breakpoints";

$msv-btn-min-width: 160px;
$msv-btn-side-padding: 20px;
$msv-btn-vertical-padding: 12px;

:root {

    // Primary button
    --msv-primary-button-background-color: var(--msv-accent-brand-color);
    --msv-primary-button-background-hover-color: #{$msv-primary-hover};
    --msv-primary-button-background-disabled-color: #{$msv-gray-300};
    --msv-primary-button-border-color: var(--msv-accent-brand-color);
    --msv-primary-button-border-width: 1px;
    --msv-primary-button-text-color:  #{$msv-white};
    --msv-primary-button-text-hover: var(--msv-accent-brand-color);
    --msv-primary-button-text-hover-deco: none;
    --msv-primary-button-border-radius: 0;

    // Secondary button
    --msv-secondary-button-background-color: #{$msv-white};
    --msv-secondary-button-background-hover-color: #{$msv-secondary-hover};
    --msv-secondary-button-background-disabled-color: #{$msv-gray-300};
    --msv-secondary-button-border-color: var(--msv-accent-brand-color);
    --msv-secondary-button-border-width: 1px;
    --msv-secondary-button-text-color: #333;
    --msv-secondary-button-text-hover: var(--msv-accent-brand-color);
    --msv-secondary-button-text-hover-deco: none;
    --msv-secondary-button-border-radius: 0;


}

@mixin button-link($color: $msv-link-color) {
    border-color: transparent;
    background-color: transparent;
    color: $color;
    font-weight: 600;


    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@mixin primary-button($bg-color: var(--msv-primary-button-background-color),
$color: var(--msv-primary-button-text-color), $border-color: var(--msv-primary-button-border-color), $border-width: var(--msv-secondary-button-border-width)) {
    background-color: $bg-color;
    border: $border-width solid $border-color;
    color: $color;
    padding: $msv-btn-vertical-padding $msv-btn-side-padding;
    min-width: $msv-btn-min-width;
    border-radius: var(--msv-primary-button-border-radius);
    transition: all .3s ease;
    text-transform: uppercase;
    font-weight: bold;

    &:hover,
    &:focus {
        background-color: var(--msv-primary-button-background-hover-color);
        color: var(--msv-primary-button-text-hover);
        text-decoration: var(--msv-primary-button-text-hover-deco);
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        border-color: var(--msv-primary-button-background-disabled-color);
        background-color: var(--msv-primary-button-background-disabled-color);
        color: var(--msv-font-secondary-color);
    }
}

@mixin secondary-button($bg-color: var(--msv-secondary-button-background-color),
$color: var(--msv-secondary-button-text-color), $border-color: var(--msv-secondary-button-border-color), $disableBtn: true, $border-width: var(--msv-secondary-button-border-width)) {
    background-color: $bg-color;
    border: $border-width solid $border-color;
    color: $color;
    padding: $msv-btn-vertical-padding $msv-btn-side-padding;
    min-width: $msv-btn-min-width;
    border-radius: var(--msv-secondary-button-border-radius);
    transition: all .3s ease;
    text-transform: uppercase;
    font-weight: bold;
    &:hover,
    &:focus {
        background-color: var(--msv-secondary-button-background-hover-color);
        color: var(--msv-secondary-button-text-hover);
        text-decoration: var(--msv-secondary-button-text-hover-deco);
    }

    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            border-color: var(--msv-secondary-button-background-disabled-color);
            background-color: var(--msv-secondary-button-background-disabled-color);
            color: var(--msv-font-secondary-color);
        }
    }
}

@mixin button-default() {
    // @include vfi();
    @include font-content-l();
    cursor: pointer;
    padding: 12px 20px;
    height: 48px;
    min-width: 160px;
    text-align: center;
}

@mixin button-icon {
    width: 48px;
    height: 48px;
}
