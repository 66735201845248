.msc-add-to-wishlist {
    @include add-icon($msv-Heart, after);

    &__removing {
        @include add-icon($msv-HeartFill, after, 600);
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
