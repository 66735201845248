
.fashion {
    &__image-wrapper {
      width: 450px;
      height: 562px;
      cursor: pointer;
      position: relative;
  
      @media screen and (max-width: 500px) {
        width: auto;
        height: auto;
        margin-bottom: 0.5rem;
      }
  
    }
  
    &__products {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid black;
      padding: 1rem;
    }
  
    &__collection-name {
      font-size: 1.5rem;
    }
  
    &__product-tile-list {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  
    &__product-details {
      width: 100%;
      padding: 0.2rem;
    }
    &__image {
      &--faded {
        opacity: 0.4;
      }
    }
    &__product-price {
      font-size: 1.5rem;
      font-weight: 600;
    }
  
    &__shop-look {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      font-family: $msv-primary-font-family;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.5rem;
      padding: 0.5rem;
    }
  
    &__actions {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  
    &__product-title {
      font-family: $msv-primary-font-family;
      font-weight: 400;
      font-style: italic;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  
    &__product-tile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      position: relative;
      padding: 0.2rem;
      &::after {
        content: '';
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 76%);
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }